import $ from "jquery";
import noUiSlider  from "nouislider";

$( document ).ready(function() {

    const workoutDatabaseOverview = document.getElementById('workout-database-overview');

    if (typeof workoutDatabaseOverview != 'undefined' && workoutDatabaseOverview != null) {


        const WorkoutDatabaseFilter = new WorkoutBuilderDatabaseFilter()
        WorkoutDatabaseFilter.setWorkouts(arrAllWorkouts)
        WorkoutDatabaseFilter.getFilteredWorkouts()

        var slider = document.getElementById('rpm-range-filter');
        noUiSlider.create(slider, {
            start: [50, 120],
            step: 1,
            connect: true,
            range: {
                'min': 50,
                'max': 120
            }
            // pips: {
            //     mode: 'count',
            //     values: 4,
            //     density: 4,
            //     stepped: true,
            // },

        });


        var filterResetButton = $("#workout-database-overview").find(".filter-reset");

        filterResetButton.each(function(){



            $(this).on("click", function() {



                $('#filter_search_text').val("")

                // range
                $('#filter_intensity').val(0)

                // select
                $('#filter_riding_technique').val(0)
                $('#filter_duration').val(0)

                //checkbox
                $('#filter_master_trainer_workout').prop('checked', false)
                $('#filter_music_link_included').prop('checked', false)

                // noUiSlider reset
                slider.noUiSlider.set([50, 120]);

                // reset values in filter class
                WorkoutDatabaseFilter.resetFilters()
            })
        })


        // filter events
        var filterSearchText = $("#filter_search_text");


        // text filter
        filterSearchText.on("keyup paste", function() {
            var value = $(this).val();
            WorkoutDatabaseFilter.setTextSearchFilter(value)
        });


        // intensity filter
        var filterIntensity = $("#filter_intensity");
        filterIntensity.on("change", function() {
            var value = $(this).val();
            WorkoutDatabaseFilter.setIntensityFilter(value)
        });

        // rpm range filter
        slider.noUiSlider.on('set', function (
                values,
                handle,
                unencoded,
                tap,
                positions
            ) {
                WorkoutDatabaseFilter.setRPMFilter(unencoded[0], unencoded[1])

            }
        );

        slider.noUiSlider.on('slide', function (
                values,
                handle,
                unencoded,
                tap,
                positions
            ) {
                WorkoutDatabaseFilter.setRPMRangeDisplay(unencoded[0], unencoded[1])
            }
        );


        // duration filter
        var filterDuration = $("#filter_duration");
        filterDuration.on("change", function() {
            var value = $(this).val();
            WorkoutDatabaseFilter.setDurationFilter(value)
        });

        // riding technique filter
        var filterRidingTechnique = $("#filter_riding_technique");
        filterRidingTechnique.on("change", function() {
            var value = $(this).val();
            WorkoutDatabaseFilter.setRidingTechniqueFilter(value)
        });


        // master trainer workout filter
        var filterMasterTrainerWorkout = $("#filter_master_trainer_workout");
        filterMasterTrainerWorkout.on("change", function() {
            var value = $(this).is(":checked");
            WorkoutDatabaseFilter.setMasterTrainerWorkoutFilter(value)
        });

        // music link included filter
        var filterMusicLinkIncluded = $("#filter_music_link_included");
        filterMusicLinkIncluded.on("change", function() {
            var value = $(this).is(":checked");
            WorkoutDatabaseFilter.setMusicLinkIncludedFilter(value)
        });






    }




    //
    //
    // $(".preview-box").on("click", function(e) {
    //     var target_url = $(this).data('preview-url')
    //     if (target_url && target_url != "#") {
    //         window.location.href = target_url
    //     } else {
    //         return
    //     }
    // })
    //
    // $(".preview-title-link").on("click", function(e) {
    //     var target_url = $(this).data('preview-url')
    //     if (target_url && target_url != "#") {
    //         window.location.href = target_url
    //     } else {
    //         return
    //     }
    // })
    //
    //

    //
    //
    // function showAllAllowedBoxes(workoutBoxes) {
    //     workoutBoxes.each(function(workoutBox){
    //         var blnShow = true
    //
    //         if ($(workoutBoxes[workoutBox]).data('search-show') != "1") {
    //             blnShow = false
    //         }
    //
    //         if ($(workoutBoxes[workoutBox]).data('duration-show') != "1") {
    //             blnShow = false
    //         }
    //
    //         if ($(workoutBoxes[workoutBox]).data('effort-show') != "1") {
    //             blnShow = false
    //         }
    //
    //         if (blnShow) {
    //             $(workoutBoxes[workoutBox]).show()
    //         } else {
    //             $(workoutBoxes[workoutBox]).hide()
    //         }
    //     });
    // }
    //
    //
    //
    // $(".filter-slider").each(function() {
    //
    //     var filterId = $(this).parent().attr("id")
    //     var workoutBoxes = $("div[data-filter-name='"+ filterId  +"']").find(".workout-box")
    //
    //     $(this).on("change", function(){
    //         var value = $(this).val()
    //
    //         var workoutBoxes = $("div[data-filter-name='"+ filterId  +"']").find(".workout-box")
    //
    //         workoutBoxes.filter(function() {
    //
    //             if ( value == 0 ) {
    //                 $(this).data("effort-show", 1)
    //                 return
    //             }
    //
    //             if ( $(this).data("effort-class") == value ) {
    //                 $(this).data("effort-show", 1)
    //             } else {
    //                 $(this).data("effort-show", 0)
    //             }
    //         });
    //
    //         showAllAllowedBoxes(workoutBoxes)
    //
    //
    //     })
    //
    // })
    //
    //
    //
    //
    //
    //
    // $('.filter-duration').each(function(){
    //
    //     var filterDuration = $(this)
    //     var filterId = $(this).parent().attr("id")
    //     var arrayFilter = eval("arrFilter"+filterId)
    //     var arrayFilterDurations = eval("arrFilter"+filterId+"Durations")
    //     var actualIndex = $(this).data("actual-index")
    //     var filterLabel = $("#filter_duration_"+ filterId +"_label")
    //     var workoutBoxes = $("div[data-filter-name='"+ filterId  +"']").find(".workout-box")
    //
    //
    //     $(this).find(".select-left").on("click", function(){
    //         actualIndex = getNextIndex(arrayFilter, actualIndex, false)
    //         $(filterDuration).data('actual-index', actualIndex)
    //         filterLabel.html(arrayFilter[actualIndex])
    //         var targetDuration = arrayFilterDurations[actualIndex]
    //
    //         workoutBoxes.each(function(workoutBox){
    //             var workoutDuration = parseInt($(workoutBoxes[workoutBox]).data("duration"))
    //
    //             if ( targetDuration == 0 ) {
    //                 $(workoutBoxes[workoutBox]).data("duration-show", 1)
    //                 return
    //             }
    //
    //             if (actualIndex == arrayFilterDurations.length-1) {
    //                 if ( workoutDuration > arrayFilterDurations[actualIndex] ) {
    //                     $(workoutBoxes[workoutBox]).data("duration-show", 1)
    //                 }else{
    //                     $(workoutBoxes[workoutBox]).data("duration-show", 0)
    //                 }
    //             } else {
    //                 if ( workoutDuration <= targetDuration ) {
    //                     $(workoutBoxes[workoutBox]).data("duration-show", 1)
    //                 }else{
    //                     $(workoutBoxes[workoutBox]).data("duration-show", 0)
    //                 }
    //             }
    //         });
    //
    //         showAllAllowedBoxes(workoutBoxes)
    //
    //     });
    //
    //     $(this).find(".select-right").on("click", function(){
    //         actualIndex = getNextIndex(arrayFilter, actualIndex, true)
    //         $(filterDuration).data('actual-index', actualIndex)
    //         filterLabel.html(arrayFilter[actualIndex])
    //
    //         var targetDuration = arrayFilterDurations[actualIndex]
    //
    //         workoutBoxes.each(function(workoutBox){
    //             var workoutDuration = parseInt($(workoutBoxes[workoutBox]).data("duration"))
    //
    //             if (targetDuration == 0) {
    //                 $(workoutBoxes[workoutBox]).data("duration-show", 1)
    //                 return
    //             }
    //
    //             if (actualIndex == arrayFilterDurations.length-1) {
    //                 if ( workoutDuration > arrayFilterDurations[actualIndex] ) {
    //                     $(workoutBoxes[workoutBox]).data("duration-show", 1)
    //                 }else{
    //                     $(workoutBoxes[workoutBox]).data("duration-show", 0)
    //                 }
    //             } else {
    //                 if ( workoutDuration <= targetDuration ) {
    //                     $(workoutBoxes[workoutBox]).data("duration-show", 1)
    //                 }else{
    //                     $(workoutBoxes[workoutBox]).data("duration-show", 0)
    //                 }
    //             }
    //         });
    //
    //         showAllAllowedBoxes(workoutBoxes)
    //
    //     });
    //
    //
    //
    // });
    //
    //
    // function getNextIndex(arrFilter, actualIndex, forward = true) {
    //
    //     var arraySize = arrFilter.length-1
    //
    //     if (forward) {
    //         if ( (actualIndex+1) <= arraySize ) {
    //             return actualIndex+1
    //         } else {
    //             return 0
    //         }
    //     } else {
    //         if ( (actualIndex-1) < 0 ) {
    //             return arraySize
    //         } else {
    //             return actualIndex-1
    //         }
    //     }
    // }


});


class WorkoutBuilderDatabaseFilter {
    constructor() {
        this.arrWorkouts = [];

        this.filter = {
            text_search: "",
            intensity: 0,
            duration: null,
            rpm_min: 50,
            rpm_max: 120,
            riding_technique: null,
            master_trainer_workout: false,
            music_link_included: false
        }

    }

    resetFilters() {
        this.filter.text_search = ""
        this.filter.intensity = 0
        this.filter.duration = null
        this.filter.rpm_min = 50
        this.filter.rpm_max = 120
        this.filter.riding_technique = null
        this.filter.master_trainer_workout = false
        this.filter.music_link_included = false

        this.setRPMRangeDisplay(50, 120)
        this.applyFilter()
    }

    setWorkouts(arrWorkouts) {
        this.arrWorkouts = arrWorkouts
    }

    getAllWorkouts() {
        return this.arrWorkouts
    }


    applyFilter() {

        let arrFilteredWorkoutIds = this.getFilteredWorkouts()

        $(".workout-box").hide()

        $.each( arrFilteredWorkoutIds, function( key, value ) {
            $("#workout-database-workout-"+ value).show()
        })


    }

    setRPMRangeDisplay(min, max) {
        $("#rpm-range-display").html(min+" - "+max)
    }


    setRPMFilter(min, max) {
        this.filter.rpm_min = min
        this.filter.rpm_max = max
        this.applyFilter()
    }

    setDurationFilter(duration) {
        this.filter.duration = parseInt(duration)
        this.applyFilter()
    }

    setIntensityFilter(intensity) {
        this.filter.intensity = parseInt(intensity)
        this.applyFilter()
    }

    setTextSearchFilter(text_search) {
        this.filter.text_search = text_search
        this.applyFilter()
    }

    setRidingTechniqueFilter(riding_technique) {
        this.filter.riding_technique = riding_technique
        this.applyFilter()
    }

    setMasterTrainerWorkoutFilter(master_trainer_workout) {
        this.filter.master_trainer_workout = master_trainer_workout
        this.applyFilter()
    }

    setMusicLinkIncludedFilter(music_link_included) {
        this.filter.music_link_included = music_link_included
        this.applyFilter()
    }


    getFilteredWorkouts() {
        // console.log("getFilteredWorkouts")
        //
        // console.log("######################################################################")
        // console.log("this.arrWorkouts",this.arrWorkouts)
        // console.log("######################### APPLIED FILTERS ############################")
        // console.log("this.filter.text_search", this.filter.text_search)
        // console.log("this.filter.intensity", this.filter.intensity)
        // console.log("this.filter.duration", this.filter.duration)
        // console.log("this.filter.rpm_min", this.filter.rpm_min)
        // console.log("this.filter.rpm_max", this.filter.rpm_max)
        // console.log("this.filter.riding_technique", this.filter.riding_technique)
        // console.log("this.filter.master_trainer_workout", this.filter.master_trainer_workout)
        // console.log("this.filter.music_link_included", this.filter.music_link_included)
        // console.log("######################################################################")

        let filteredWorkouts = this.arrWorkouts


        // apply intensity filter
        if (this.filter.intensity > 0) {
            filteredWorkouts = filteredWorkouts.filter(workout => workout.intensity == this.filter.intensity)
        }

        // console.log(" after intensity filter", filteredWorkouts)

        //apply duration filter
        if (typeof this.filter.duration != 'undefined' && this.filter.duration != null) {

            if (this.filter.duration >= 3601) {
                //more than 1 hour
                filteredWorkouts = filteredWorkouts.filter(workout => workout.duration > this.filter.duration)
            } else if (this.filter.duration == 0) {
                filteredWorkouts = filteredWorkouts.filter(workout => workout.duration > 0)
            } else {
                filteredWorkouts = filteredWorkouts.filter(workout => workout.duration <= this.filter.duration)
            }
        }

        // console.log(" after duration filter", filteredWorkouts)

        //apply RT filter
        if (typeof this.filter.riding_technique != 'undefined' && this.filter.riding_technique != null) {
            filteredWorkouts = filteredWorkouts.filter(workout => workout.riding_techniques.includes(this.filter.riding_technique))
        }

        // console.log(" after RT filter", filteredWorkouts)

        //apply rpm filter
        filteredWorkouts = filteredWorkouts.filter(workout => workout.rpm_min >= this.filter.rpm_min && workout.rpm_max <= this.filter.rpm_max)

        // console.log(" after rpm filter", filteredWorkouts)

        //apply master trainer filter
        if (this.filter.master_trainer_workout) {
            filteredWorkouts = filteredWorkouts.filter(workout => workout.master_trainer === true)
        }

        // console.log(" after master trainer filter", filteredWorkouts)

        //apply music link filter
        if (this.filter.music_link_included) {
            filteredWorkouts = filteredWorkouts.filter(workout => workout.music_link !== "")
        }

        // console.log(" after music link filter", filteredWorkouts)

        // apply text search filter
        if (this.filter.text_search != "") {
            filteredWorkouts = filteredWorkouts.filter(workout => workout.text_searchable.toLowerCase().includes(this.filter.text_search.toLowerCase()) )
        }

        // console.log(" after text search filter", filteredWorkouts)


        // console.log("ALL ilteredWorkouts")
        // console.log(filteredWorkouts)

        var arrFilteredWorkoutIds = filteredWorkouts.map(function(obj) {
            return obj.id;
        });

        return arrFilteredWorkoutIds
    }



}


