import $ from "jquery";

$( document ).ready(function() {

    $("a.download_certificate_with_provider_selection").click(function(e) {
        var bookingId = $(this).data("booking-id")
        var linkUrl = $(this).attr("href");


        var selectProvider = $('#download_certificate_provider_'+ bookingId)
        if (selectProvider.length) {
            e.preventDefault()
            window.location.href = linkUrl + "?provider=" + $(selectProvider).children("option:selected").val()
        }
    });

    $("a.download_live_certificate_with_provider_selection").click(function(e) {
        var bookingId = $(this).data("booking-id")
        var linkUrl = $(this).attr("href");

        var selectProvider = $('#download_live_certificate_provider_'+ bookingId)
        if (selectProvider.length) {
            e.preventDefault()
            window.location.href = linkUrl + "?provider=" + $(selectProvider).children("option:selected").val()
        }
    });

    $("a.download_legacy_certificate_with_provider_selection").click(function(e) {
        var certTypeId = $(this).data("cert-type-id")
        var linkUrl = $(this).attr("href");


        var selectProvider = $('#download_legacy_certificate_provider_'+ certTypeId)
        if (selectProvider.length) {
            e.preventDefault()
            window.location.href = linkUrl + "?provider=" + $(selectProvider).children("option:selected").val()
        }
    });


    // voucher code reedeming

    $("#check_voucher_code_button").on("click", function(e) {


        var voucherCode = $('#voucher_code_input_field').val()

        if (voucherCode != "") {
            checkVoucherCode(voucherCode)
        }
    })

    function checkVoucherCode(voucherCode) {
        var resultTextField = $('#voucher_code_result_text')
        var locale_url = $('meta[name=locale]').attr("content")
        var CSRFToken = $("meta[name='csrf-token']").attr('content')

        var modalVoucherCodeProfile = $('#voucher_code_modal_profile')
        var modalVoucherCodeError = $('#voucher_code_modal_error')
        var modalTextFieldError = $('#voucher_code_modal_result_text_error')
        var modalTextFieldProfile = $('#voucher_code_modal_result_text_profile')

        voucherCode = voucherCode.trim()

        $.ajax({
            ///:locale/account/voucher_codes/:voucher_code_id/check_vc(.:format)
            url : '/'+ locale_url +'/account/voucher_codes/validate/check_vc',
            dataType: "json",
            method : "POST",
            headers: {
                'X-CSRF-TOKEN': CSRFToken
            },
            data:{
                voucher_code: voucherCode,
            },
            success:function(response) {

                modalTextFieldProfile.html(response.message)
                modalVoucherCodeProfile.modal('show')
                resultTextField.text(response.message).show()
                setTimeout(function(){
                    location.reload();
                }, 5000);
            },
            error:function(e){
                //error handling
                if (e.responseJSON.error_code == "_NOT_INVOICEABLE_") {
                    modalTextFieldProfile.html(e.responseJSON.message)
                    modalVoucherCodeProfile.modal('show')
                    return
                }

                if (e.responseJSON.error_code == "_INVALID_") {
                    modalTextFieldError.html(e.responseJSON.message)
                    modalVoucherCodeError.modal('show')
                    resultTextField.text(e.responseJSON.message).show()
                    return
                }

                if (e.responseJSON.error_code == "_CM_INVALID_") {
                    modalTextFieldError.html(e.responseJSON.message)
                    modalVoucherCodeError.modal('show')
                    resultTextField.text(e.responseJSON.message).show()
                    return
                }

                if (e.responseJSON.error_code == "_VA_INVALID_") {
                    modalTextFieldError.html(e.responseJSON.message)
                    modalVoucherCodeError.modal('show')
                    resultTextField.text(e.responseJSON.message).show()
                    return
                }

                if (e.responseJSON.error_code == "_PA_INVALID_") {
                    modalTextFieldError.html(e.responseJSON.message)
                    modalVoucherCodeError.modal('show')
                    resultTextField.text(e.responseJSON.message).show()
                    return
                }

                if (e.responseJSON.error_code == "_NOT_INVOICEABLE_") {
                    modalTextFieldError.html(e.responseJSON.message)
                    modalVoucherCodeError.modal('show')
                    resultTextField.text(e.responseJSON.message).show()
                    return
                }

                if (e.responseJSON.error_code == "_CM_ALREADY_GIC_MEMBER_") {
                    modalTextFieldError.html(e.responseJSON.message)
                    modalVoucherCodeError.modal('show')
                    resultTextField.text(e.responseJSON.message).show()
                    return
                }




                modalTextFieldError.html(e.responseJSON.message)
                modalVoucherCodeError.modal('show')
                resultTextField.text(e.responseJSON.message).show()
                return;

            }
        });

    }



});



