import $ from "jquery";

$( document ).ready(function() {


    const workoutOverview = document.getElementById('workout-overview');

    if (typeof workoutOverview != 'undefined' && workoutOverview != null) {



        $(".preview-box").on("click", function(e) {
            var target_url = $(this).data('preview-url')
            if (target_url && target_url != "#") {
                window.location.href = target_url
            } else {
                return
            }
        })

        $(".preview-title-link").on("click", function(e) {
            var target_url = $(this).data('preview-url')
            if (target_url && target_url != "#") {
                window.location.href = target_url
            } else {
                return
            }
        })


        // modal listener for publishing
        $('.publish-workout-link').on('click', function(e) {
            e.preventDefault();
            var workoutId = $(this).data('workout-id');



            var publishModal = $('#custom_workout_publish')
            var publishLink = $(this)


            var doPublishButton = $(publishModal).find('#button-do-publish')
            var publishUrl = $(publishModal).find('#button-do-publish').data('post-url')

            $('#custom_workout_publish').modal("show")



            $(doPublishButton).on('click', function(e) {
                doPublish(workoutId, publishUrl, publishLink, publishModal)
            });


        })

        function doPublish(workoutId, publish_url, publishLink, publishModal) {


            let url = publish_url

            var CSRFToken = $("meta[name='csrf-token']").attr('content')

            $.ajax({
                url: url,
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': CSRFToken
                },
                dataType: "json",
                data: {
                    custom_workout_id: workoutId
                },
                success: function(response) {


                    // on success,  change button to "published"
                    publishLink.text( publishLink.data('published-text') )
                    publishLink.removeClass('publish-workout-link')
                    publishLink.off()

                    //close modal
                    publishModal.modal("hide")



                },
                error: function(xhr, status, error) {
                    console.error(error); // Fängt Fehler ab
                }
            });



        }



        $('.filter-reset').each(function(){
            var filterId = $(this).parent().attr("id")
            var arrayFilter = eval("arrFilter"+filterId)


            $(this).on("click", function() {
                $('#filter_search_'+filterId).val("")
                $('#filter_range_'+filterId).val(0)
                //$('#filter_duration_'+filterId).data('actual-index', 0)
                //$('#filter_duration_'+filterId+'_label').html(arrayFilter[0])

                $('#filter_duration_'+filterId).val(0).trigger('change')



                var workoutBoxes = $("div[data-filter-name='"+ filterId  +"']").find(".workout-box")

                workoutBoxes.each(function(workoutBox){
                    $(workoutBoxes[workoutBox]).data('search-show', 1)
                    $(workoutBoxes[workoutBox]).data('duration-show', 1)
                    $(workoutBoxes[workoutBox]).data('effort-show', 1)
                });

                showAllAllowedBoxes(workoutBoxes)
            })
        })


        function showAllAllowedBoxes(workoutBoxes) {
            workoutBoxes.each(function(workoutBox){
                var blnShow = true

                if ($(workoutBoxes[workoutBox]).data('search-show') != "1") {
                    blnShow = false
                }

                if ($(workoutBoxes[workoutBox]).data('duration-show') != "1") {
                    blnShow = false
                }

                if ($(workoutBoxes[workoutBox]).data('effort-show') != "1") {
                    blnShow = false
                }

                if (blnShow) {
                    $(workoutBoxes[workoutBox]).show()
                } else {
                    $(workoutBoxes[workoutBox]).hide()
                }
            });
        }



        $(".filter-slider").each(function() {

            var filterId = $(this).parent().attr("id")
            var workoutBoxes = $("div[data-filter-name='"+ filterId  +"']").find(".workout-box")

            $(this).on("change", function(){
                var value = $(this).val()

                var workoutBoxes = $("div[data-filter-name='"+ filterId  +"']").find(".workout-box")

                workoutBoxes.filter(function() {

                    if ( value == 0 ) {
                        $(this).data("effort-show", 1)
                        return
                    }

                    if ( $(this).data("effort-class") == value ) {
                        $(this).data("effort-show", 1)
                    } else {
                        $(this).data("effort-show", 0)
                    }
                });

                showAllAllowedBoxes(workoutBoxes)


            })

        })

        $(".filter-search").each(function() {
            var filterId = $(this).parent().attr("id")
            var workoutBoxes = $("div[data-filter-name='"+ filterId  +"']").find(".workout-box")

            $(this).on("keyup paste", function() {

                var value = $(this).val().toLowerCase();

                workoutBoxes.filter(function() {
                    if ( $(this).data("workout-name").toLowerCase().indexOf(value) > -1 ) {
                        $(this).data("search-show", 1)
                    } else if (  $(this).data("creator-name") && $(this).data("creator-name").toLowerCase().indexOf(value) > -1 ) {
                        $(this).data("search-show", 1)
                    } else {
                        $(this).data("search-show", 0)
                    }

                });

                showAllAllowedBoxes(workoutBoxes)

            })

        })


        $(".filter-select").each(function() {



            var filterId = $(this).attr("id").replace("filter_duration_", "")
            var workoutBoxes = $("div[data-filter-name='"+ filterId  +"']").find(".workout-box")


            $(this).on('change', function() {

                var selectedDuration = $(this).val()
                selectedDuration = parseInt(selectedDuration)

                workoutBoxes.each(function(workoutBox){
                    var workoutDuration = parseInt($(workoutBoxes[workoutBox]).data("duration"))

                    if ( selectedDuration == 0 ) {
                        $(workoutBoxes[workoutBox]).data("duration-show", 1)
                        return
                    }

                    if ( selectedDuration >= 3601 ) {

                        if ( workoutDuration > selectedDuration ) {
                            $(workoutBoxes[workoutBox]).data("duration-show", 1)
                        } else{
                            $(workoutBoxes[workoutBox]).data("duration-show", 0)
                        }


                    } else {

                        if ( workoutDuration <= selectedDuration ) {
                            $(workoutBoxes[workoutBox]).data("duration-show", 1)
                        } else{
                            $(workoutBoxes[workoutBox]).data("duration-show", 0)
                        }

                    }


                });

                showAllAllowedBoxes(workoutBoxes)
            })

        })


        $('.filter-duration-DEPRECATED').each(function(){

            var filterDuration = $(this)
            var filterId = $(this).parent().attr("id")
            var arrayFilter = eval("arrFilter"+filterId)
            var arrayFilterDurations = eval("arrFilter"+filterId+"Durations")
            var actualIndex = $(this).data("actual-index")
            var filterLabel = $("#filter_duration_"+ filterId +"_label")
            var workoutBoxes = $("div[data-filter-name='"+ filterId  +"']").find(".workout-box")


            $(this).find(".select-left").on("click", function(){
                actualIndex = getNextIndex(arrayFilter, actualIndex, false)
                $(filterDuration).data('actual-index', actualIndex)
                filterLabel.html(arrayFilter[actualIndex])
                var targetDuration = arrayFilterDurations[actualIndex]

                workoutBoxes.each(function(workoutBox){
                    var workoutDuration = parseInt($(workoutBoxes[workoutBox]).data("duration"))

                    if ( targetDuration == 0 ) {
                        $(workoutBoxes[workoutBox]).data("duration-show", 1)
                        return
                    }




                    if (actualIndex == arrayFilterDurations.length-1) {
                        if ( workoutDuration > arrayFilterDurations[actualIndex] ) {
                            $(workoutBoxes[workoutBox]).data("duration-show", 1)
                        }else{
                            $(workoutBoxes[workoutBox]).data("duration-show", 0)
                        }
                    } else {
                        if ( workoutDuration <= targetDuration ) {
                            $(workoutBoxes[workoutBox]).data("duration-show", 1)
                        }else{
                            $(workoutBoxes[workoutBox]).data("duration-show", 0)
                        }
                    }
                });

                showAllAllowedBoxes(workoutBoxes)

            });

            $(this).find(".select-right").on("click", function(){
                actualIndex = getNextIndex(arrayFilter, actualIndex, true)
                $(filterDuration).data('actual-index', actualIndex)
                filterLabel.html(arrayFilter[actualIndex])

                var targetDuration = arrayFilterDurations[actualIndex]

                workoutBoxes.each(function(workoutBox){
                    var workoutDuration = parseInt($(workoutBoxes[workoutBox]).data("duration"))

                    if (targetDuration == 0) {
                        $(workoutBoxes[workoutBox]).data("duration-show", 1)
                        return
                    }

                    if (actualIndex == arrayFilterDurations.length-1) {
                        if ( workoutDuration > arrayFilterDurations[actualIndex] ) {
                            $(workoutBoxes[workoutBox]).data("duration-show", 1)
                        }else{
                            $(workoutBoxes[workoutBox]).data("duration-show", 0)
                        }
                    } else {
                        if ( workoutDuration <= targetDuration ) {
                            $(workoutBoxes[workoutBox]).data("duration-show", 1)
                        }else{
                            $(workoutBoxes[workoutBox]).data("duration-show", 0)
                        }
                    }
                });

                showAllAllowedBoxes(workoutBoxes)

            });



        });


        function getNextIndex(arrFilter, actualIndex, forward = true) {

            var arraySize = arrFilter.length-1

            if (forward) {
                if ( (actualIndex+1) <= arraySize ) {
                    return actualIndex+1
                } else {
                    return 0
                }
            } else {
                if ( (actualIndex-1) < 0 ) {
                    return arraySize
                } else {
                    return actualIndex-1
                }
            }
        }
    }





});



