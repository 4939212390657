import $ from "jquery";
import datepicker from 'js-datepicker'


$( document ).ready(function() {



    const course_start_field = $('#live_certificate_course_start')
    const course_end_field = $('#live_certificate_course_end')

    if ( course_start_field.length > 0) {
        //datepicker
        const courseStart = datepicker('#datepicker-course-start-select', {
            id: 1,
            maxDate: new Date(),
            formatter: (input, date, instance) => {
                const value = getDateInFormat(date)
                input.value = value // => '1/1/2099'
            },
            onSelect: (instance, date) => {
                const value = getDateInFormat(date)
                $('#live_certificate_course_start').val(value)
            },

        })
    }

    if ( course_end_field.length > 0) {
        const courseEnd = datepicker('#datepicker-course-end-select', {
            id: 1,
            maxDate: new Date(),
            formatter: (input, date, instance) => {
                const value = getDateInFormat(date)
                input.value = value // => '1/1/2099'
            },
            onSelect: (instance, date) => {
                const value = getDateInFormat(date)
                $('#live_certificate_course_end').val(value)
            },
        })
    }





    function getDateInFormat(objDate) {
        return objDate.getFullYear() + "-" + (objDate.getMonth() + 1) + "-" + objDate.getDate();
    }


    var countrySelection = $("select[name='live_certificate[country_id]']");
    var countryStateSelection = $("select[name='live_certificate[country_state_id]']");
    var declineCommentBox = $('.decline-comment-block')
    var declineBox = $("input[id='live_certificate_decline_request']");

    var arrPossibleCountryState = getPossibleCountryIdsInStates();

    countrySelection.on('change', function (e) {
        if (arrPossibleCountryState.indexOf(parseInt($(this).val())) != -1) {
            countryStateSelection.show();
        } else {
            //hide state selection
            countryStateSelection.val(null)
            countryStateSelection.hide();
        }
    });

    setTimeout(function () {
        countrySelection.trigger('change');
        declineBox.trigger('change')
    }, 200)


    function getPossibleCountryIdsInStates() {

        var arrCountryId = [];
        var countryStateSelection = $("select[name='live_certificate[country_state_id]']");
        var stateOptions = countryStateSelection.find('option');

        stateOptions.each(function (index) {
            if (arrCountryId.indexOf($(this).data('country')) == -1) {
                arrCountryId.push($(this).data('country'));
            } else {
                return
            }
        });

        return arrCountryId;

    }


    if (declineBox.length > 0 ) {
        declineBox.on('change', function (e) {
            if ($(this).is(':checked')) {
                declineCommentBox.show()
            } else {
                declineCommentBox.hide()
                //empty text
                declineCommentBox.find("#live_certificate_decline_message_box").val("")
            }
        });
    }



});



