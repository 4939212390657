import { Calendar } from '@fullcalendar/core';
import { formatDate } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import { toMoment, toDuration } from '@fullcalendar/moment';
import $ from "jquery";

document.addEventListener('DOMContentLoaded', function() {

    var calendarEl = document.getElementById('calendar');


    if (calendarEl == null)
        return false

    var x = getOffset( document.getElementById('calendar') );

    if ( findGetParameter("calendar") == "1" ) {
        if (window.innerWidth < 1200) {
            window.scrollTo(0, x.top - 120)
        } else {
            window.scrollTo(0, x.top - 180)
        }

    }

    var calendar = new Calendar(calendarEl, {
        plugins: [ dayGridPlugin, interactionPlugin, momentPlugin  ],
        defaultDate: showMonth,
        events: activities,
        locale: calendarLocale,
        firstDay: 1,
        header: {
            left: "prev",
            center: "title",
            right: "today, next"
        },
        buttonText: {
            today: " "
        },
        height: 400,
        contentHeight: 250,
        datesRender: function(info) {

            var currentMonth = toMoment(info.view.currentStart, calendar)
            var strMonth = currentMonth.locale("en").format("YYYY-MM")


            showActivities(strMonth)
            showMonthlyPerformance(strMonth)
        },
    });


    function showActivities(strMonth) {
        $(".activity-item").hide();

        $(".activity-date-" + strMonth).each(function() {
            $(this).show()
        })
    }

    function showMonthlyPerformance(strMonth) {
        $(".monthly-performance-box").hide();
        var blnShown = false
        $(".monthly_statistics-" + strMonth).each(function() {
            if ($(this).parent().show()) {
                blnShown = true
            }

        })

        if (!blnShown) {
            $(".monthly_statistics-no-data").each(function() {
                $(this).parent().show()
            })
        }

    }


    function getOffset( el ) {
        var _x = 0;
        var _y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    }


    function redirectToDate(strDate) {

        var url = window.location.href.split("#")
        url = url[0]
        url = window.location.href.split("?")
        url = url[0]
        window.location.href = url + "?calendar=1&showdate="+strDate;
    }

    function findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }


    calendar.render();
});
