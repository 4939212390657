import $ from "jquery";


$( document ).ready(function() {



    var clubMembershipForm = $('#new_club_membership_bundle')

    if (clubMembershipForm.length > 0) {
        setTimeout(function () {
            //console.log("ClubMemberShipBundle triggered...")
            buildSummary()
        }, 500)


        clubMembershipForm.on('submit', function(e){
            e.preventDefault();
            var button =  $("#club_membership_bundle_form_submit_button")
            button.prop('disabled', true)

            if (   checkDoubleCourse(null) ) {

                if ( checkQuantity() ) {
                    this.submit()
                } else {
                    var noQuantityText = $('#template_new_club_membership_bundle_course_no_quantity_message').text()
                    alert(noQuantityText)
                    button.removeAttr('disabled')
                    button.prop('disabled', false)
                    button.attr('disabled', false)
                }
            } else {
                button.removeAttr('disabled')
                button.prop('disabled', false)
                button.attr('disabled', false)
            }
            
        });

    }

    function checkQuantity() {
        var quantityFieldValue = $('#club_membership_bundle_quantity').val()

        if (quantityFieldValue === null) {
            quantityFieldValue = 0
        }

        if ( quantityFieldValue >= 1 ) {
            return true
        }else{
            return false
        }
    }



    clubMembershipForm.on('change', function(obj) {
        //console.log("change")

        checkDoubleCourse(obj.target)

        buildSummary()
    })

    function checkDoubleCourse(obj) {
        var courseArea = $('#courses_area')
        var selectedCourses = courseArea.find("[id^='club_membership_bundle_course_']")
        var arrCourses = []
        var errorText = $('#template_new_club_membership_bundle_course_double_message').text()

        selectedCourses.each(function( index ){
            arrCourses.push( parseInt($(this).val() ))
        });

        if (getDuplicateArrayElements(arrCourses).length > 0) {
            alert(errorText)

            if ( obj !== null ) {
                $(obj).find("option[value='']").attr('selected', true)
            }


            var button =  $("#club_membership_bundle_form_submit_button")

            setTimeout(function(){
                button.removeAttr('disabled')
                button.prop('disabled', false)
                button.attr('disabled', false)

            }, 200);


            return false;
        }

        return true;
    }

    function getDuplicateArrayElements(arr){
        var sorted_arr = arr.slice().sort();
        var results = [];
        for (var i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] === sorted_arr[i]) {
                results.push(sorted_arr[i]);
            }
        }
        return results;
    }

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    //##################### COURSES

    // $('#add_club_membership_bundle_course_button').on("click", function(e){
    //     addClubMembershipCourse(e.target)
    // })


    $( "[id^='remove_club_membership_bundle_course_persistent_']" ).on('click', function(e){
        $(this).parent().remove()
        buildSummary()
    });


    // function addClubMembershipCourse(target) {
    //     //get data
    //     var courseId = "new-"+ makeid(4);
    //
    //     //render answer
    //     var JsCourseField = $('#template_new_club_membership_bundle_course').html();
    //     Mustache.parse(JsCourseField);   // optional, speeds up future uses
    //
    //     var rendered = Mustache.render(JsCourseField,
    //         {
    //             course_id: courseId
    //         });
    //
    //     $('#courses_area').append(rendered);
    //
    //     $("#remove_club_membership_bundle_course_"+courseId).on("click", function(e){
    //         $(this).parent().remove();
    //         buildSummary();
    //     })
    //
    //
    //     buildSummary();
    // }


    function clearSummary() {
        //console.log("clear Summary...")
        $('#dynamic_summary').html("")
    }

    function buildSummary() {

        //console.log("build summary!")
        clearSummary()


        addFreeCourseIncludedLine()


        //multiplier / quantity
        addMultiplierLine()

        calculateTotal()
    }

    function addFreeCourseIncludedLine() {

        var freeCoursesIncluded = $('#club_membership_bundle_free_courses_included').val()
        var freeCoursesTitleText = "+ "+freeCoursesIncluded+" courses included";
        var sum = 0
        var sumFormatted = "EURO € "+sum.toFixed(2)


        if (freeCoursesIncluded === null) {
            freeCoursesIncluded = 1
        }

        if (freeCoursesIncluded > 1) {
            sum = ((freeCoursesIncluded-1)*25)
            var sumFormatted = "EURO € "+sum.toFixed(2)
        }

        var JsSummaryLine = $('#template_new_club_membership_bundle_free_courses_included_line').html();
        Mustache.parse(JsSummaryLine);   // optional, speeds up future uses
        var rendered = Mustache.render(JsSummaryLine,
            {
                free_courses_text: freeCoursesTitleText,
                free_courses_amount_text: sumFormatted,
                free_courses_price: sum
            });

        $('#dynamic_summary').append(rendered);
    }

    function addMultiplierLine() {

        var quantity = $('#club_membership_bundle_quantity').val()
        var multiplierTitleText = "x club memberships";
        var multiplierAmountText = "x "+quantity
        var multiplierTitleTextHint = " (please select)"

        if (quantity === null) {
            quantity = 0

            multiplierTitleText += multiplierTitleTextHint
            multiplierAmountText = "x "+quantity
        }

        var JsSummaryLine = $('#template_new_club_membership_bundle_multiplier_line').html();
        Mustache.parse(JsSummaryLine);   // optional, speeds up future uses
        var rendered = Mustache.render(JsSummaryLine,
            {
                multiplier_text: multiplierTitleText,
                multiplier_amount_text: multiplierAmountText,
                multiplier_value: quantity
            });

        $('#dynamic_summary').append(rendered);
    }



    function calculateTotal() {
        //console.log("calculate Total")

        var finalPriceField = $('#club_membership_final_price')
        var toBeSummedUp = $('#summary_area').find('.to-be-summed')
        var sum = 0
        var sumFormatted = "EURO € "+sum.toFixed(2)

        toBeSummedUp.each(function(index){
            //console.log( parseFloat($(this).val()) )
            if ( $(this).val() === null ) {
                return
            }
            sum += parseFloat($(this).val())
        });


        //add quantity
        var quantity = $('#club_membership_bundle_quantity').val();

        if (quantity === null) {
            quantity = 0
        }
        sum = sum*quantity
        sumFormatted = "EURO € "+sum.toFixed(2)
        finalPriceField.text(sumFormatted)

        $('#club_membership_bundle_final_price').val(sum)


        //console.log("SUMME", sum)

    }






});



