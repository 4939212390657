const HandBook = function(elm){
    this.hbCta = document.getElementById('handbook-menu-cta');
    this.asideNav = document.getElementById('handbook-aside');
    this.menuItems = document.getElementsByClassName('handbook-menu-items');
    this.bookItems = document.getElementsByClassName('handbook-nav-item');
    this.navOV = document.getElementById('aside_ov');
};

let navStatus = false;
let navArr = [];


HandBook.prototype = {
    init: function () {
        this.listenEvents();
    },
    listenEvents: function() {
        const self = this;
        self.hbCta.addEventListener('click', function () {
            if(!navStatus){
                self.openAsideNav();
            }else{
                self.closeAsideNav();
            }
        });

        self.navOV.addEventListener('click', function(){
            if(navStatus){
                self.closeAsideNav();
            }
        });

        for(let i = 0; i < self.menuItems.length; i++){
            self.menuItems[i].addEventListener('click', function(){
                let navNum = this.getAttribute('data-menu-type');
                self.menuItems.className = '';
                self.activeList(this);
                self.changeContent(navNum);
                self.openAsideNav();
            })
        }

        for(let i = 0; i < self.bookItems.length; i++){
            navArr.push(self.bookItems[i]);
        }

    },

    openAsideNav: function () {
        const self = this;
        self.asideNav.classList.add("asideNavOpen");
        self.navOV.classList.add("activeOV");
        navStatus = true;
    },
    closeAsideNav: function () {
        const self = this;
        self.asideNav.classList.remove("asideNavOpen");
        self.navOV.classList.remove("activeOV");
        navStatus = false;
    },
    activeList: function (elm) {
        const self = this;
        self.removeList(self.menuItems);
        elm.classList.add('handbook-menu-list_active');
    },
    removeList: function (obj) {
        const self = this;
        for (let i = 0; i < obj.length; i++){
            obj[i].classList.remove('handbook-menu-list_active');
        }
    },
    changeContent: function (num) {
        const self = this;
        for (let key in navArr) {
            if(key == num){
                navArr[key].classList.add("aktive-item");
            }else{
                navArr[key].classList.remove("aktive-item");
            }
        }
    }

};

document.addEventListener('DOMContentLoaded', function(){
    if(document.getElementById('aside_ov')){
        new HandBook('aside_ov').init();
    }
});



