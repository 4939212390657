// import { Calendar } from '@fullcalendar/core';
// import { formatDate } from '@fullcalendar/core'
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
//
// document.addEventListener('DOMContentLoaded', function() {
//     var calendarEl = document.getElementById('schedule-calendar');
//
//     if (calendarEl == null)
//         return false
//
//     var calendar = new Calendar(calendarEl, {
//         plugins: [ timeGridPlugin ],
//         timeZone: 'UTC',
//         defaultView: 'timeGridWeek',
//         header: {
//             left: 'prev,next today',
//             center: 'title',
//             right: 'timeGridWeek,timeGridDay'
//         },
//         events: [
//             {
//                 start: '2023-05-25T10:00:00',
//                 end: '2023-05-25T12:00:00',
//                 title: 'Sample Event',
//                 description: 'Event description goes here',
//                 languageType: 'English'
//             },
//             {
//                 start: '2023-05-25T13:00:00',
//                 end: '2023-05-25T16:00:00',
//                 title: 'Sample Event',
//                 description: 'Event description goes here',
//                 languageType: 'English'
//             },
//             // Add more events here
//         ],
//     });
//
//     calendar.render();
//
//     calendar.render();
// });

import { Calendar } from '@fullcalendar/core';
// import { formatDate } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';
// import momentPlugin from '@fullcalendar/moment';
// import { toMoment, toDuration } from '@fullcalendar/moment';
// import $ from "jquery";

document.addEventListener('DOMContentLoaded', function() {

    var calendarEl = document.getElementById('schedule-calendar');

    if (calendarEl == null)
        return false


    var calendar = new Calendar(calendarEl, {
        plugins: [ dayGridPlugin, timeGridPlugin ],
        defaultView: 'timeGridWeek',
        header: {
            left: "",
            right: "",
            center: '',
        },
        slotLabelFormat: {
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12: false
        },
        columnHeaderFormat: { weekday: 'long' },
        height: "auto",
        allDaySlot: false,
        locale: calendarLocale,
        firstDay: 1,
        //timeGridEventMinHeight: "30px",
        minTime: minTime,
        maxTime: maxTime,
        nowIndicator: true,
        dayMinWidth: "300px",
        eventOverlap: false,
        slotLabelInterval: {
            minutes:30
        },
        // events: schedule_events,
        events: schedule_events,
        eventRender: function(info) {

            console.log(info)
            console.log(info.event.title)



            var eventCategory = info.event.extendedProps.category;
            var eventWorkoutType = info.event.extendedProps.workoutType;
            var eventLanguageType = info.event.extendedProps.language;
            var eventDuration = info.event.extendedProps.workoutDuration;

            var eventTitle = info.event.title;
            var eventIsLive = info.event.extendedProps.isLive;
            var eventTrainers = info.event.extendedProps.trainers;

            // Customize the event rendering here
            var eventElement = info.el.querySelector('.fc-content');

            var elementLeft = '<strong>' + eventWorkoutType + '<br>' + eventCategory + '</strong>';
            var elementRight = '<strong>' + eventDuration + '<br>' + eventLanguageType + '</strong>';


            if (eventIsLive == "true") {
                var elementLeft = '<strong>' + eventTitle + '<br>' + eventTrainers + '</strong>';
                var elementRight = '<strong>' + eventDuration + '</strong>';
            }



            eventElement.innerHTML = '<div class="fc-content-left">' + elementLeft + '</div><div class="fc-content-right">' + elementRight + '</div>';



        }

    });



    calendar.render();
});
