const ExamTimer = function(elm){
    this.elmId = document.getElementById(elm);
    this.elmTime = this.elmId.dataset.seconds;
    this.elmRedirectPath = this.elmId.dataset.redirecturl
    this.displayTime = document.getElementById('time');
};


ExamTimer.prototype = {
    init: function () {
        this.listenEvents();
    },
    listenEvents: function() {
        const self = this;

        let minutes = this.elmTime,
            display = self.displayTime;

        self.createTimer();
        self.startTimer(minutes, display);

    },
    createTimer: function () {
        const newSpan = document.createElement('span');

        const minuteDiv = document.createElement("div");
        const minuteSpan = document.createElement('span');
        minuteDiv.id = "minutes";
        minuteDiv.textContent = "Minutes";
        minuteDiv.appendChild(minuteSpan);

        const secondDiv = document.createElement("div");
        const secondSpan = document.createElement('span');
        secondDiv.id = "seconds";
        secondDiv.textContent = "Seconds";
        secondDiv.appendChild(secondSpan);

        this.displayTime.appendChild(minuteDiv);
        this.displayTime.appendChild(secondDiv);
    },
    startTimer: function(duration, display){
        const self = this;
        let timer = duration, minutes, seconds;

        const minutesContainer = document.querySelector('#minutes > span');
        const secondsContainer = document.querySelector('#seconds > span');

        let interval = setInterval(function(){

            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            minutesContainer.textContent = minutes;
            secondsContainer.textContent = seconds;

            if(--timer < 0){
                clearInterval(interval);
                self.timeIsFinished();
            }

        }, 1000);
    },
    timeIsFinished: function(){
        alert("Unfortunately time is up! You'll be redirect to your account...");
        window.location.replace(this.elmRedirectPath);


        return false;
    }
};

document.addEventListener('DOMContentLoaded', function(){
    if(document.getElementById('timer_seconds')){
        new ExamTimer('timer_seconds').init();
    }
});
