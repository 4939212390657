import $ from "jquery";
import 'slick-slider';

const ProgramsSlider = function( id, index ){
    this.slider = $('#'+id);
    this.index = index;
};

ProgramsSlider.prototype = {
    init: function(){
        this.initSlider();
        this.listenEvents();
    },
    initSlider: function(){



        /*----------  Carousel Slick ----------*/
        $('#slick'+this.index).slick({
            infinite: false,
            dots: false,
            arrows: false,
            fade: true,
            autoplay: false,
            swipe: false,
            touchMove: false,
            centerMode: false,
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1
        });

        this.slider.slick({
            infinite: false,
            dots: false,
            prevArrow: $('.prev-'+ this.index),
            nextArrow: $('.next-'+ this.index),
            autoplay: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: false,
            mobileFirst: false,
            variableWidth: false,
            adaptiveHeight: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },

            ]
        });
    },
    listenEvents: function(){
        let self = this;
        let colorFilter = [];
        let colorFilterString ="";

        let typeFilter = [];
        let typeFilterString = "";

        $('.program--buttons-'+this.index+' button').bind('click', function(e){
            let filter = $(this).val();
            let filterType = $(this).attr("data-filterType");

            if(filterType == "Color")
            {
                colorFilter = [];
                colorFilter.push('[data-color=' + filter + ']');
                colorFilterString = colorFilter.join(',');
            }

            $('.slick-thumbs-'+self.index).slick('slickUnfilter');

            if(colorFilterString != "")
            {
                $('.slick-thumbs-'+self.index).slick('slickFilter', function() {
                    return $(colorFilterString, this).length === 1;
                });
            }

            if (filterType == "Clear")
            {
                colorFilter = [];
                colorFilterString ="";
                typeFilter = [];
                typeFilterString = "";
                $('.slick-thumbs-'+self.index).slick('slickUnfilter');
            }

            $('#slick'+self.index+', .slick-thumbs-'+self.index).slick('slickGoTo', 0);

        });
    }
};

document.addEventListener('DOMContentLoaded', function(){
    const slides = document.getElementsByClassName( 'slick-thumbs' );

    for(let i = 0; i < slides.length; i++){
        let specific_id = slides[i].id.split("-");
        new ProgramsSlider(slides[i].id, specific_id[specific_id.length-1]).init();
    }


/*
    if(document.getElementById( 'slick-thumbs' )){
        new ProgramsSlider('slick-thumbs').init();
    }
    if(document.getElementById( 'slick-thumbs2' )){
        new ProgramsSlider('slick-thumbs2').init();
    }
*/
});





