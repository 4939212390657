import $ from "jquery";


$( document ).ready(function() {
    $("a[href='#top']").click(function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    $("a[href^='#samepage-']").click(function() {
        var target = $("a[name="+$(this).attr('href').replace(/#/, "")+"]").parents(".container ")


        if (target.length < 1) {
            return false;
        }

        $("html, body").animate({ scrollTop: target.offset().top-100 }, "slow");
        return false;
    });

    $("a[href^='#live-ride-listing']").click(function() {
        var target = $("a[name="+$(this).attr('href').replace(/#/, "")+"]")
        
        if (target.length < 1) {
            return false;
        }

        $("html, body").animate({ scrollTop: target.offset().top-100 }, "slow");
        return false;
    });

    $("a[href^='#gic-become-member']").click(function() {
        var target = $("a[name="+$(this).attr('href').replace(/#/, "")+"]")


        if (target.length < 1) {
            return false;
        }

        $("html, body").animate({ scrollTop: target.offset().top-100 }, "slow");
        return false;
    });


});

