import $ from "jquery";

document.addEventListener('DOMContentLoaded', function(){

    //time and zones
    $(".single-item").each(function(){
        $(this).slick({
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: true,
            prevArrow: false,
            nextArrow: false
        });
    });


    var x = getOffset( document.getElementById('activity-details-main') );

    if ( findGetParameter("show_activity") !== null ) {
        if (window.innerWidth < 1200) {
            window.scrollTo(0, x.top - 120)
        } else {
            window.scrollTo(0, x.top - 180)
        }

    }




    // diagrams
    const activityBoxes = document.getElementsByClassName( 'activity_diagram' );
    //var fontRoboto = new Font('Noto Regular', fontFile('GUBBLO___.ttf'));
    
    for(let i = 0; i < activityBoxes.length; i++){
        var width, height;
        var padding = 10; // padding in percent
        var canvasId = activityBoxes[i].id
        var activityId = canvasId.split("-")[1]
        var activityData = eval("activityData_"+activityId)

        var parsed_ftp_level_history = activityData.ftp_level_history;
        var parsed_time_in_all_zones = activityData.time_in_all_zones;
        
        // check if object is a string... if so -> parse it!
        if ( typeof parsed_ftp_level_history === "string") {
            parsed_ftp_level_history = JSON.parse(parsed_ftp_level_history)
        }

        // check if object is a string... if so -> parse it!
        if ( typeof parsed_time_in_all_zones === "string") {
            parsed_time_in_all_zones = JSON.parse(parsed_time_in_all_zones)
        }

        var c = document.getElementById(canvasId);

        var ctx = c.getContext("2d");

        ctx.stroke();

        drawHistogram(ctx, padding, parsed_ftp_level_history[60]);
    }

    function drawHistogram(ctx, padding, valueArray) {

        valueArray = valueArray.reverse();

        const maxSteps = 60;
        let drawLength = valueArray.length;

        let stepWidth = 6; // maxStepsize

        stepWidth = (stepWidth + (stepWidth * 2 / 7)); // fix margin right


        const size = (stepWidth * 4 / 7); // size per bar (5/7 of space)
        const margin = size / 2; // radius of linecap
        const height = ctx.canvas.height;

        ctx.lineWidth = size;
        ctx.lineCap = 'round';

        for (let index = 0; index < drawLength; index++) {
            const x = index * stepWidth;

            if ( index == 14 ||
                index == 29 ||
                index == 44 ||
                index == 59 ||
                index == 74 ||
                index == 89 ||
                index == 104 ||
                index == 119 ||
                index == 134 ||
                index == 149 ||
                index == 164 ||
                index == 179 ||
                index == 194 ||
                index == 209 ||
                index == 224 ||
                index == 239 ||
                index == 254 ||
                index == 269 ||
                index == 284 ||
                index == 299 ||
                index == 314 ||
                index == 329
            ) {
                var fontSize = 11;
                ctx.font = fontSize + 'px Arial';
                ctx.fillStyle = 'rgb(255,255,255)';
                ctx.lineWidth = 2;
                ctx.strokeStyle = 'rgba(255,255,255,1)';
                ctx.beginPath();
                ctx.moveTo(padding + x  , 14);
                ctx.lineTo(padding + x , 15);
                ctx.stroke();
                ctx.fillText(index+1, padding + x-6, 10);
            }


            ctx.lineWidth = size;
            ctx.strokeStyle = 'rgba(255,255,255,0.2)';
            ctx.beginPath();

            ctx.moveTo(padding + x, padding+10);
            ctx.lineTo(padding + x, height-padding);
            ctx.stroke();

            if (valueArray[index]) {
                const phase = getColorPhaseByFTP(valueArray[index]);
                let intensity = (valueArray[index] / 151);

                if (valueArray[index] > 151.0) {
                    intensity = 1
                }

                ctx.strokeStyle = phase.colorCode;
                ctx.beginPath();
                ctx.moveTo(padding + x, height-padding);

                let upper_y = ((height-padding)-( height-padding )  * intensity)+padding + 10

                if (upper_y > (height-padding)) {
                    upper_y = (height-padding)
                }

                ctx.lineTo(padding + x, upper_y);
                ctx.stroke();
            }
        }
    }
    
    function getColorPhaseByFTP(ftp) {
        if (ftp < 55) {
            return { color: 'white', min: 0, max: 55, icon: 'circle-p0', animation: 'none', colorCode: '#FFFFFF', textColor: '#000000', phase: 0, phaseOld: 0 };
        } else if (ftp < 67) {
            // endurance
            return { color: 'blue', min: 55, max: 67, icon: 'circle-p1', animation: 'none', colorCode: '#3EB7EA', textColor: '#FFFFFF', phase: 1, phaseOld: 1 };
        } else if (ftp < 76) {
            // ignite animation
            return { color: 'blue', min: 67, max: 76, icon: 'circle-p2', animation: 'ignite', colorCode: '#3EB7EA', textColor: '#FFFFFF', phase: 2, phaseOld: 1 };
        } else if (ftp < 85) {
            // tempo
            return { color: 'green', min: 76, max: 85, icon: 'circle-p3', animation: 'none', colorCode: '#65B22E', textColor: '#000000', phase: 3, phaseOld: 2 };
        } else if (ftp < 91) {
            // heat green animation
            return { color: 'green', min: 85, max: 91, icon: 'circle-p4', animation: 'heat', colorCode: '#65B22E', textColor: '#000000', phase: 4, phaseOld: 2 };
        } else if (ftp < 96) {
            // lectate yellow animation
            return { color: 'yellow', min: 91, max: 96, icon: 'circle-p4', animation: 'heat', colorCode: '#F6E71B', textColor: '#000000', phase: 5, phaseOld: 3 };
        } else if (ftp < 106) {
            // lectate yellow
            return { color: 'yellow', min: 96, max: 106, icon: 'circle-p5', animation: 'none', colorCode: '#F6E71B', textColor: '#000000', phase: 6, phaseOld: 3 };
        } else if (ftp < 121) {
            // anearobic capacity
            return { color: 'red', min: 106, max: 121, icon: 'circle-p6', animation: 'none', colorCode: '#E42320', textColor: '#FFFFFF', phase: 7, phaseOld: 4 };
        } else if (ftp < 151) {
            // fire
            return { color: 'red', min: 121, max: 151, icon: 'circle-p7', animation: 'fire', colorCode: '#E42320', textColor: '#FFFFFF', phase: 8, phaseOld: 4 };
        } else if (ftp >= 151) {
            // burn
            return { color: 'red', min: 151, max: 999, icon: 'circle-p8', animation: 'burn', colorCode: '#E42320', textColor: '#FFFFFF', phase: 9, phaseOld: 4 };
        }
    }


    function getOffset( el ) {
        var _x = 0;
        var _y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    }

    function findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }


});


