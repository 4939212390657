const Exam = function(elms){
    this.elms = document.getElementsByClassName(elms);
    this.answers = document.getElementsByClassName('answers-wrap');
    this.form = $('form.exam')
};

Exam.prototype = {
    init: function () {
        this.listenEvents();
    },
    listenEvents: function() {
        const self = this;

        self.form.on('submit', function(e) {
            if ( self.allAnswered() ) {
                return true;
            } else {
                e.preventDefault();
                alert( translations.answer_all );
                return false;
            }

        });
    },
    allAnswered: function() {
        const self = this;
        for(let i = 0; i < this.answers.length; i++){
            let thisAnswer = this.answers[i];
            let getGroupID = thisAnswer.querySelectorAll('li')[0].dataset.group;
            let radioInputs = thisAnswer.querySelector('input[name="answers[q_'+ getGroupID +']"]:checked');

            if(radioInputs === null){
                self.scrollToExam(thisAnswer);
                return false;
            }
        }
        return true;
    },
    scrollToExam: function(elm){
        let scrollContainer = elm.dataset.question;
        smoothScroll(document.getElementById(scrollContainer));
    }
};

document.addEventListener('DOMContentLoaded', function(){
    if(document.getElementsByClassName('exam')[0]){
        new Exam('exam').init();
    }
});

window.smoothScroll = function(target) {
    var scrollContainer = target;
    do { //find scroll container
        scrollContainer = scrollContainer.parentNode;
        if (!scrollContainer) return;
        scrollContainer.scrollTop += 1;
    } while (scrollContainer.scrollTop == 0);

    var targetY = 0;
    do { //find the top of target relatively to the container
        if (target == scrollContainer) break;
        targetY += target.offsetTop;
    } while (target = target.offsetParent);

    scroll = function(c, a, b, i) {


        i++; if (i > 30) return;
        c.scrollTop = a + (b - a) / 30 * i;
        setTimeout(function(){ scroll(c, a, b, i); }, 20);
    }
    // start scrolling
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
}


