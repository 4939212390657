import TweenLite from 'gsap'
import 'gsap/CSSPlugin'

const Navigation = function( id ){
    this.items = document.getElementById( id ).querySelectorAll('.nav-list-main-item > a');
    this.subItems = document.getElementById( id ).querySelectorAll('.nav-list-main-item > .nav-list-sub');

    this.widthWidth = document.getElementsByTagName('body')[0];
    this.windowSize = null;
};

Navigation.prototype = {
    init: function(){
        this.listenEvents();
    },
    listenEvents: function(){
        const self = this;
        const navList = self.items;
        let getCurrentBackBtn = document.querySelectorAll('.nav-list-sub-back');

        self.resize();
        window.onresize = self.resize;

        for (let i = 0; i < navList.length; i++){
            navList[i].addEventListener('click', function (e) {
                e.preventDefault();
                let getCurrentSubList = navList[i].parentNode.querySelectorAll('.nav-list-sub');

                for (let i = 0; i < navList.length; i++){
                    //self.slideUp(navList[i]);
                    self.windowSize < 1200 ? self.slideLeft(navList[i]) : self.slideUp(navList[i]);


                }
                //self.slideDown(getCurrentSubList[0]);
                self.windowSize < 1200 ? self.slideRight(getCurrentSubList[0]) : self.slideDown(getCurrentSubList[0]);
            });

            for(let i  = 0; i < getCurrentBackBtn.length; i++){
                getCurrentBackBtn[i].addEventListener('click', function () {

                    //self.slideUp(getCurrentBackBtn[i].parentNode);
                    self.windowSize < 1200 ? self.slideLeft(getCurrentBackBtn[i].parentNode) : self.slideUp(getCurrentBackBtn[i].parentNode);
                    for (let i = 0; i < navList.length; i++){
                        //self.slideDown(navList[i]);
                        self.windowSize < 1200 ? self.slideRight(navList[i]) : self.slideDown(navList[i]);
                    }
                })
            }
        }
    },
    slideUp: function (elem) {
        TweenLite.fromTo(elem,1,{top: 0}, {top: 100});
    },
    slideDown: function (elem) {
        TweenLite.fromTo(elem,1,{top: 100}, {top: 0}).delay(1.2);
    },
    slideLeft: function (elem) {
        TweenLite.fromTo(elem,1,{left: '0%'}, {left: '100%'});
    },
    slideRight: function (elem) {
        TweenLite.fromTo(elem,1,{left: '100%'}, {left: '0%'}).delay(1.2);
    },
    resize: function () {
        const self = this;
        self.windowSize = window.innerWidth;

    }

};

document.addEventListener('DOMContentLoaded', function(){
    // if(document.getElementById('nav-control--main')){
    //     new Navigation('nav-list-wrap').init();
    // }
});
