const BarBackground = function( id ){
    this.header = document.getElementsByTagName('header')[0];
};

BarBackground.prototype = {
    init: function(){
        this.listenEvents();

    },
    listenEvents: function(){
        const self = this;
        window.addEventListener('scroll', function() {


            if (window.scrollY > 30) {
                self.header.classList.add('start-animation');
            } else {
                self.header.classList.remove('start-animation');
            }
        });
    }
};

document.addEventListener('DOMContentLoaded', function(){
    new BarBackground('header').init();
});
