import $ from "jquery";
import Chart from 'chart.js';
import datepicker from "js-datepicker";
import moment from "moment";


$( document ).ready(function() {

    let labels = []


    const pointRadius = 4
    const pointHoverRadius = 6


    let data = {
        labels: labels,
        datasets: [
            {
                id: "calories",
                label: 'Calories',

                yAxisID: "yCalories",

                borderColor: '#FFFACE',
                data: [],
                tension: 0,
                fill: false,


                pointBackgroundColor: '#FFFACE',
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius
            },
            {
                id: "distance",
                label: 'Distance (in km)',

                yAxisID: "yDistance",

                //backgroundColor: 'rgb(255, 99, 132)',
                borderColor: '#FFFACE',
                data: [],
                tension: 0,
                fill: false,

                pointBackgroundColor: '#FFFACE',
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius
            },
            {
                id: "ep",
                label: 'Effort Points',

                yAxisID: "yEP",

                //backgroundColor: 'rgb(255, 99, 132)',
                borderColor: '#FFFACE',
                data: [],
                tension: 0,
                fill: false,

                pointBackgroundColor: '#FFFACE',
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius
            },
            {
                id: "watt",
                label: 'average Watt',

                yAxisID: "yAvgWatt",

                //backgroundColor: 'rgb(255, 99, 132)',
                borderColor: '#FFFACE',
                data: [],
                tension: 0,
                fill: false,

                pointBackgroundColor: '#FFFACE',
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius
            },
            {
                id: "watt-kg",
                label: 'average Watt/Kg',

                yAxisID: "yAvgWattKg",

                //backgroundColor: 'rgb(255, 99, 132)',
                borderColor: '#FFFACE',
                data: [],
                tension: 0,
                fill: false,

                pointBackgroundColor: '#FFFACE',
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius
            },
            {
                id: "ftp",
                label: 'FTP',

                yAxisID: "yFtp",

                //backgroundColor: 'rgb(255, 99, 132)',
                borderColor: '#FFFACE',
                data: [],
                tension: 0,
                fill: false,

                pointBackgroundColor: '#FFFACE',
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius
            },
            {
                id: "duration",
                label: 'Duration',




                yAxisID: "yDuration",

                //backgroundColor: 'rgb(255, 99, 132)',
                borderColor: '#FFFACE',
                data: [],
                tension: 0,
                fill: false,

                pointBackgroundColor: '#FFFACE',
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius
            },
            {
                id: "tss",
                label: 'TSS',

                yAxisID: "yTSS",

                //backgroundColor: 'rgb(255, 99, 132)',
                borderColor: '#FFFACE',
                data: [],
                tension: 0,
                fill: false,

                pointBackgroundColor: '#FFFACE',
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius
            },
            {
                id: "if",
                label: 'IF',

                yAxisID: "yIF",

                //backgroundColor: 'rgb(255, 99, 132)',
                borderColor: '#FFFACE',
                data: [],
                tension: 0,
                fill: false,

                pointBackgroundColor: '#FFFACE',
                pointRadius: pointRadius,
                pointHoverRadius: pointHoverRadius
            }
        ]
    };

    let options = {
        layout: {
            //padding: 50,
        },
        defaultColor: "white",

        legend: {
          display: false
        },
        style : {

        },
        scales: {

            xAxes: [{
                ticks: {
                    fontColor: 'white'
                },
            }],

            yCalories: {
                min: 50,
            },

            yAxes: [
                {

                    id: "yCalories",
                    type: 'linear',
                    position: 'left',
                    display: false,

                    scaleLabel: {
                        display: true,
                        padding: 0,
                        fontSize: 16,
                        fontColor: "#FFFACE",
                        labelString: "Calories (in kcal)"
                    },

                    ticks: {

                        beginAtZero: true,
                        stepSize: 100,
                        fontColor: 'white',

                        callback: function(value, index, values) {
                            return value;
                        }
                    }
                },
                {
                    id: "yDistance",
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    display: false,
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        fontSize: 16,
                        fontColor: "#FFFACE",
                        labelString: "Distance (in km)"
                    },
                    ticks: {
                        beginAtZero: true,
                        fontColor: 'white',
                        callback: function(value, index, values) {
                            return value;
                        }
                    }
                },
                {
                    id: "yEP",
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    display: false,
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        fontSize: 16,
                        fontColor: "#FFFACE",
                        labelString: "Effort Points"
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: 25,
                        fontColor: 'white',
                        callback: function(value, index, values) {
                            return value;
                        }
                    }
                },
                {
                    id: "yAvgWatt",
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    display: false,
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        fontSize: 16,
                        fontColor: "#FFFACE",
                        labelString: "Ø Watt (in W)"
                    },
                    ticks: {
                        beginAtZero: true,
                        fontColor: 'white',

                        callback: function(value, index, values) {
                            return value;
                        }
                    }
                },
                {
                    id: "yAvgWattKg",
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    display: false,
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        fontSize: 16,
                        fontColor: "#FFFACE",
                        labelString: "Ø Watt/KG (in W/KG)"
                    },
                    ticks: {
                        beginAtZero: true,
                        fontColor: 'white',

                        callback: function(value, index, values) {
                            return value;
                        }
                    }
                },
                {
                    id: "yFtp",
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    display: false,
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        fontSize: 16,
                        fontColor: "#FFFACE",
                        labelString: "Ø FTP"
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        fontColor: 'white',
                    }
                },
                {
                    id: "yDuration",
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    display: false,
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        fontSize: 16,
                        fontColor: "#FFFACE",
                        labelString: "Duration (in minutes)"
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: 600,
                        fontColor: 'white',
                        callback: function(value, index, values) {
                            var minutes = moment.duration(value*1000).asMinutes()

                            if (minutes < 1) {
                                return 1
                            }

                            return minutes;
                        }
                    }
                },
                {
                    id: "yTSS",
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    display: false,
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        fontSize: 16,
                        fontColor: "#FFFACE",
                        labelString: "Ø TSS"
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        fontColor: 'white',
                    }
                },
                {
                    id: "yIF",
                    type: 'linear',
                    position: 'left',
                    min: 0,
                    display: false,
                    scaleLabel: {
                        display: true,
                        padding: 0,
                        fontSize: 16,
                        fontColor: "#FFFACE",
                        labelString: "Ø IF"
                    },
                    ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        fontColor: 'white',
                    }
                },

            ]
        },



        tooltips: {
            callbacks: {
                label: function(context, datasetLabels) {


                    const dataPoint = datasetLabels.datasets[context.datasetIndex].data[context.index]

                    let toReturn = dataPoint

                    switch (activeGroupSelector) {
                        case "days":

                            if (context.datasetIndex == 0) {
                                toReturn =  datasetLabels.datasets[context.datasetIndex].label + ": " + dataPoint + " kcal";
                            }

                            if (context.datasetIndex == 1) {
                                toReturn =   datasetLabels.datasets[context.datasetIndex].label + ": " + dataPoint + " km";
                            }

                            if (context.datasetIndex == 2) {
                                let value = parseInt(dataPoint)
                                toReturn =   datasetLabels.datasets[context.datasetIndex].label + ": " + value + " EP";
                            }

                            if (context.datasetIndex == 3) {
                                let value = (Math.round(dataPoint))
                                toReturn =   datasetLabels.datasets[context.datasetIndex].label + ": Ø " + value + " Watt";
                            }

                            if (context.datasetIndex == 4) {
                                let value = (Math.round(dataPoint * 10) / 10).toFixed(1)
                                toReturn =   datasetLabels.datasets[context.datasetIndex].label + ": Ø " + value + " Watt/KG";
                            }

                            if (context.datasetIndex == 5) {
                                let value = parseInt(dataPoint)
                                toReturn =   datasetLabels.datasets[context.datasetIndex].label + ": " + value + " FTP";
                            }

                            if (context.datasetIndex == 6) {
                                let minutes = getHoursMinutesString( parseInt(dataPoint), true )
                                toReturn =   datasetLabels.datasets[context.datasetIndex].label + ": " + minutes;
                            }

                            if (context.datasetIndex == 7) {
                                let value = parseInt(dataPoint)
                                toReturn =   datasetLabels.datasets[context.datasetIndex].label + ": " + value + " TSS";
                            }

                            if (context.datasetIndex == 8) {
                                let value = (Math.round(dataPoint * 10) / 10).toFixed(1)
                                toReturn =   datasetLabels.datasets[context.datasetIndex].label + ": " + value + " IF";
                            }

                            break;

                        case "weeks":
                        case "months":
                            toReturn = []

                            // calories
                            if (context.datasetIndex == 0) {

                                let avg = dataPoint.avg.toFixed(1)
                                let max = dataPoint.max
                                let min = dataPoint.min
                                let total = dataPoint.total

                                toReturn.push("Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + avg + " kcal")
                                toReturn.push("max. " + datasetLabels.datasets[context.datasetIndex].label + ": " + max + " kcal")
                                toReturn.push("min. " + datasetLabels.datasets[context.datasetIndex].label + ": " + min + " kcal")
                                toReturn.push("total " + datasetLabels.datasets[context.datasetIndex].label + ": " + total + " kcal")
                                toReturn.push("activities: " + dataPoint.count)
                            }

                            // distance
                            if (context.datasetIndex == 1) {

                                let avg = dataPoint.avg.toFixed(1)
                                let max = dataPoint.max
                                let min = dataPoint.min
                                let total = (Math.round(dataPoint.total * 10) / 10).toFixed(1)

                                toReturn.push("Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + avg + " km")
                                toReturn.push("max. " + datasetLabels.datasets[context.datasetIndex].label + ": " + max + " km")
                                toReturn.push("min. " + datasetLabels.datasets[context.datasetIndex].label + ": " + min + " km")
                                toReturn.push("total " + datasetLabels.datasets[context.datasetIndex].label + ": " + total + " km")
                                toReturn.push("activities: " + dataPoint.count)

                            }

                            // ep
                            if (context.datasetIndex == 2) {

                                let avg = dataPoint.avg.toFixed(1)
                                let max = dataPoint.max
                                let min = dataPoint.min
                                let total = parseInt(dataPoint.total)

                                toReturn.push("Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + avg + " EP")
                                toReturn.push("max. " + datasetLabels.datasets[context.datasetIndex].label + ": " + max + " EP")
                                toReturn.push("min. " + datasetLabels.datasets[context.datasetIndex].label + ": " + min + " EP")
                                toReturn.push("total " + datasetLabels.datasets[context.datasetIndex].label + ": " + total + " EP")
                                toReturn.push("activities: " + dataPoint.count)
                            }

                            // avg watt
                            if (context.datasetIndex == 3) {

                                let avg = dataPoint.avg.toFixed(1)
                                let max = (Math.round(dataPoint.max))
                                let min = (Math.round(dataPoint.min))

                                toReturn.push("Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + avg + " W")
                                toReturn.push("max. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + max + " W")
                                toReturn.push("min. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + min + " W")
                                toReturn.push("activities: " + dataPoint.count)
                            }

                            // avg watt/kg
                            if (context.datasetIndex == 4) {

                                let avg = dataPoint.avg.toFixed(1)
                                let max = (Math.round(dataPoint.max * 10) / 10).toFixed(1)
                                let min = (Math.round(dataPoint.min * 10) / 10).toFixed(1)

                                toReturn.push("Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + avg + " W/KG")
                                toReturn.push("max. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + max + " W/KG")
                                toReturn.push("min. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + min + " W/KG")
                                toReturn.push("activities: " + dataPoint.count)
                            }

                            // ftp
                            if (context.datasetIndex == 5) {

                                let avg = dataPoint.avg.toFixed(1)
                                let max = (Math.round(dataPoint.max))
                                let min = (Math.round(dataPoint.min))

                                toReturn.push("Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + avg + "")
                                toReturn.push("max. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + max + "")
                                toReturn.push("min. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + min + "")
                                toReturn.push("activities: " + dataPoint.count)
                            }

                            // duration
                            if (context.datasetIndex == 6) {

                                var avg_minutes = getHoursMinutesString( Math.round(dataPoint.avg), true )
                                var max_minutes = getHoursMinutesString( dataPoint.max, true )
                                var min_minutes = getHoursMinutesString( dataPoint.min, true )
                                var total_minutes = getHoursMinutesString( dataPoint.total, true )

                                toReturn.push("Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + avg_minutes)
                                toReturn.push("max. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + max_minutes)
                                toReturn.push("min. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + min_minutes)
                                toReturn.push("total " + datasetLabels.datasets[context.datasetIndex].label + ": " + total_minutes)
                                toReturn.push("activities: " + dataPoint.count)

                            }

                            // tss
                            if (context.datasetIndex == 7) {

                                let avg = dataPoint.avg.toFixed(1)
                                let max = (Math.round(dataPoint.max))
                                let min = (Math.round(dataPoint.min))

                                toReturn.push("Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + avg + "")
                                toReturn.push("max. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + max + "")
                                toReturn.push("min. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + min + "")
                                toReturn.push("activities: " + dataPoint.count)
                            }

                            // if
                            if (context.datasetIndex == 8) {

                                let avg = dataPoint.avg.toFixed(1)
                                let max = (Math.round(dataPoint.max * 10) / 10).toFixed(1)
                                let min = (Math.round(dataPoint.min * 10) / 10).toFixed(1)

                                toReturn.push("Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + avg + "")
                                toReturn.push("max. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + max + "")
                                toReturn.push("min. Ø " + datasetLabels.datasets[context.datasetIndex].label + ": " + min + "")
                                toReturn.push("activities: " + dataPoint.count)

                                console

                            }


                            break;

                    }


                    return toReturn

                }
            }
        }




    }

    const plugin = {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart) => {
            const ctx = chart.canvas.getContext('2d');
            ctx.save();

            CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
                if (width < 2 * radius) radius = width / 2;
                if (height < 2 * radius) radius = height / 2;
                this.beginPath();
                this.moveTo(x + radius, y);
                this.arcTo(x + width, y, x + width, y + height, radius);
                this.arcTo(x + width, y + height, x, y + height, radius);
                this.arcTo(x, y + height, x, y, radius);
                this.arcTo(x, y, x + width, y, radius);
                this.closePath();
                return this;
            }

            ctx.roundRect(0, 0, chart.width, chart.height, 50);


            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'rgba(255, 255, 255, 0.15)';

            ctx.fill();

            ctx.restore();
        }
    };


    const config = {
        type: 'line',
        data,
        options: options,
       // plugins: [plugin],
    };


    var ctx = document.getElementById('graphs')


    if (typeof(ctx) != 'undefined' && ctx != null) {
        var chart =  new Chart(ctx, config)
    }


    // date selections
    const activity_graphs_start_field = $('#datepicker-activity_graphs_start_date')
    const activity_graphs_end_field = $('#datepicker-activity_graphs_end_date')

    const earliestWorkoutAt = $('#earliest-workout').data('earliest-workout-at')
    const initialStartDate = activity_graphs_start_field.val()
    const initialEndDate = activity_graphs_end_field.val()

    var globalActivityData = {}

    let startDateForRequest = initialStartDate
    let endDateForRequest = initialEndDate


    let activities = []

    if (typeof(arrDatesWithActivities) != 'undefined' && arrDatesWithActivities != null) {
        activities = arrDatesWithActivities
    }


    if ( activity_graphs_start_field.length > 0) {
        //datepicker
        const dateStart = datepicker('#datepicker-activity_graphs_start_date', {
            id: 1,
            startDay: 1,
            minDate: new Date(earliestWorkoutAt),
            maxDate: new Date(),
            dateSelected: new Date(initialStartDate),

            events: activities,

            formatter: (input, date, instance) => {
                const value = getDateInFormat(date)
                input.value = value // => '1/1/2099'
            },
            onSelect: (instance, date) => {

                if (typeof date == "undefined") {
                    return
                }

                const value = getDateInFormat(date)
                activity_graphs_start_field.val(value)
                startDateForRequest = value

                setNewTcxUrl(startDateForRequest, endDateForRequest)

                getDateForDateRange(startDateForRequest, endDateForRequest);
            },

        })
    }

    if ( activity_graphs_end_field.length > 0) {
        const dateEnd = datepicker('#datepicker-activity_graphs_end_date', {
            id: 1,
            startDay: 1,
            minDate: new Date(earliestWorkoutAt),
            maxDate: new Date(),
            dateSelected: new Date(initialEndDate),

            events: activities,

            formatter: (input, date, instance) => {
                const value = getDateInFormat(date)
                input.value = value // => '1/1/2099'
            },

            onSelect: (instance, date) => {

                if (typeof date == "undefined") {
                    return
                }

                const value = getDateInFormat(date)
                activity_graphs_end_field.val(value)

                endDateForRequest = value

                setNewTcxUrl(startDateForRequest, endDateForRequest)

                getDateForDateRange(startDateForRequest, endDateForRequest);

            },
        })
    }


    function getDateInFormat(objDate) {
        return objDate.getFullYear() + "-" + (objDate.getMonth() + 1) + "-" + objDate.getDate();
    }


    function getDateForDateRange(startDate, endDate) {

        var locale_url = $('meta[name=locale]').attr("content")
        var CSRFToken = $("meta[name='csrf-token']").attr('content')

        // logic to get new data
        var getData = function() {
            $.ajax({
                url: '/'+locale_url+'/account/app/activities/get_data',

                dataType: "json",
                method : "POST",
                headers: {
                    'X-CSRF-TOKEN': CSRFToken
                },
                data:{
                    start_date: startDate,
                    end_date: endDate,
                },

                success: function(data) {


                    ajaxResponse = data

                    //update graph
                    updateGraphsForSelectedType()
                    updateStatisticIndicators()
                },

                error:function(e){
                    //error handling
                    return


                }

            });
        };

        getData()

    }


    function setChartJsOptionsAxesForDays() {

        chart.options.scales.xAxes = options.scales.xAxes
        chart.options.scales.yAxes = options.scales.yAxes

    }

    function setChartJsOptionsForWeeks() {



        chart.options.scales.yAxes[0].ticks.callback = function(value, index, values) {
            return value;
        }

        chart.options.scales.yAxes[1].ticks.callback = function(value, index, values) {
            return value;
        }

        chart.options.scales.yAxes[2].ticks.callback = function(value, index, values) {
            return value;
        }

        chart.options.scales.yAxes[3].ticks.callback = function(value, index, values) {
            return value;
        }

        chart.options.scales.yAxes[4].ticks.callback = function(value, index, values) {
            return value;
        }

        chart.options.scales.yAxes[5].ticks.callback = function(value, index, values) {
            return value;
        }

        chart.options.scales.yAxes[6].ticks.callback = function(value, index, values) {
            var minutes = moment.duration(value*1000).asMinutes()
            return minutes;
        }

        chart.options.scales.yAxes[7].ticks.callback = function(value, index, values) {
            return value;
        }

        chart.options.scales.yAxes[8].ticks.callback = function(value, index, values) {
            return value;
        }




    }


    function setChartJsDatasetsForDays() {


        let labels = []

        ajaxResponse.data.time_data.days.calories.forEach( function(arrValue) {
            labels.push(arrValue[0])
        } )


        chart.data.datasets.forEach(function(dataset, index){

            let values = []


            switch ( dataset.id ) {
                case "calories":
                    ajaxResponse.data.time_data.days.calories.forEach( function(arrValue) {
                        values.push(arrValue[1])
                    } )
                    chart.data.datasets[index].data = values
                    break;
                case "distance":
                    ajaxResponse.data.time_data.days.distance.forEach( function(arrValue) {
                        values.push(arrValue[1])
                    } )

                    chart.data.datasets[index].data = values
                    break;
                case "ep":
                    ajaxResponse.data.time_data.days.ep.forEach( function(arrValue) {
                        values.push(arrValue[1])
                    } )

                    chart.data.datasets[index].data = values
                    break;
                case "watt":
                    ajaxResponse.data.time_data.days.avg_watt.forEach( function(arrValue) {
                        values.push(arrValue[1])
                    } )
                    chart.data.datasets[index].data = values
                    break;
                case "watt-kg":
                    ajaxResponse.data.time_data.days.avg_watt_kg.forEach( function(arrValue) {
                        values.push(arrValue[1])
                    } )

                    chart.data.datasets[index].data = values
                    break;
                case "ftp":
                    ajaxResponse.data.time_data.days.ftp.forEach( function(arrValue) {
                        values.push(arrValue[1])
                    } )
                    chart.data.datasets[index].data = values
                    break;
                case "duration":
                    ajaxResponse.data.time_data.days.duration.forEach( function(arrValue) {
                        values.push(arrValue[1])
                    } )

                    chart.data.datasets[index].data = values
                    break;

                case "tss":
                    ajaxResponse.data.time_data.days.tss.forEach( function(arrValue) {
                        values.push(arrValue[1])
                    } )
                    chart.data.datasets[index].data = values
                    break;
                case "if":
                    ajaxResponse.data.time_data.days.if.forEach( function(arrValue) {
                        values.push(arrValue[1])
                    } )
                    chart.data.datasets[index].data = values
                    break;
            }

            chart.data.labels = labels
            chart.data.datasets[index].hidden = true


        })







    }

    function setChartJsDatasetsForWeeks() {


        let labels = []




        for (var key in ajaxResponse.data.time_data.weeks){
            labels.push( ajaxResponse.data.time_data.weeks[key].cw_title )
        }

        let values_0 = []
        let values_1 = []
        let values_2 = []
        let values_3 = []
        let values_4 = []
        let values_5 = []
        let values_6 = []
        let values_7 = []
        let values_8 = []


        for (var key in ajaxResponse.data.time_data.weeks){



            // 0 calories
            if (ajaxResponse.data.time_data.weeks[key].calories) {
                values_0.push(
                    {
                        y: ajaxResponse.data.time_data.weeks[key].calories.total,
                        min: ajaxResponse.data.time_data.weeks[key].calories.min,
                        max: ajaxResponse.data.time_data.weeks[key].calories.max,
                        count: ajaxResponse.data.time_data.weeks[key].calories.activities,
                        avg: ajaxResponse.data.time_data.weeks[key].calories.avg,
                        total: ajaxResponse.data.time_data.weeks[key].calories.total
                    }
                )
            }

            // 1 distance
            if (ajaxResponse.data.time_data.weeks[key].distance) {
                values_1.push(
                    {
                        y: ajaxResponse.data.time_data.weeks[key].distance.total,
                        min: ajaxResponse.data.time_data.weeks[key].distance.min,
                        max: ajaxResponse.data.time_data.weeks[key].distance.max,
                        count: ajaxResponse.data.time_data.weeks[key].distance.activities,
                        avg: ajaxResponse.data.time_data.weeks[key].distance.avg,
                        total: ajaxResponse.data.time_data.weeks[key].distance.total
                    }
                )
            }

            // 2 ep
            if (ajaxResponse.data.time_data.weeks[key].ep) {
                values_2.push(
                    {
                        y: ajaxResponse.data.time_data.weeks[key].ep.total,
                        min: ajaxResponse.data.time_data.weeks[key].ep.min,
                        max: ajaxResponse.data.time_data.weeks[key].ep.max,
                        count: ajaxResponse.data.time_data.weeks[key].ep.activities,
                        avg: ajaxResponse.data.time_data.weeks[key].ep.avg,
                        total: ajaxResponse.data.time_data.weeks[key].ep.total
                    }
                )
            }


            // 3 avg watt
            if (ajaxResponse.data.time_data.weeks[key].avg_watt) {
                values_3.push(
                    {
                        y: ajaxResponse.data.time_data.weeks[key].avg_watt.avg,
                        min: ajaxResponse.data.time_data.weeks[key].avg_watt.min,
                        max: ajaxResponse.data.time_data.weeks[key].avg_watt.max,
                        count: ajaxResponse.data.time_data.weeks[key].avg_watt.activities,
                        avg: ajaxResponse.data.time_data.weeks[key].avg_watt.avg,
                        total: ajaxResponse.data.time_data.weeks[key].avg_watt.total
                    }
                )
            }


            // 4 avg watt/kg
            if (ajaxResponse.data.time_data.weeks[key].avg_watt_kg) {
                values_4.push(
                    {
                        y: ajaxResponse.data.time_data.weeks[key].avg_watt_kg.avg,
                        min: ajaxResponse.data.time_data.weeks[key].avg_watt_kg.min,
                        max: ajaxResponse.data.time_data.weeks[key].avg_watt_kg.max,
                        count: ajaxResponse.data.time_data.weeks[key].avg_watt_kg.activities,
                        avg: ajaxResponse.data.time_data.weeks[key].avg_watt_kg.avg,
                        total: ajaxResponse.data.time_data.weeks[key].avg_watt_kg.total
                    }
                )
            }

            // 5 ftp
            if (ajaxResponse.data.time_data.weeks[key].ftp) {
                values_5.push(
                    {
                        y: ajaxResponse.data.time_data.weeks[key].ftp.avg,
                        min: ajaxResponse.data.time_data.weeks[key].ftp.min,
                        max: ajaxResponse.data.time_data.weeks[key].ftp.max,
                        count: ajaxResponse.data.time_data.weeks[key].ftp.activities,
                        avg: ajaxResponse.data.time_data.weeks[key].ftp.avg,
                        total: ajaxResponse.data.time_data.weeks[key].ftp.total
                    }
                )
            }

            // 6 duration
            if (ajaxResponse.data.time_data.weeks[key].duration) {
                values_6.push(
                    {
                        y: ajaxResponse.data.time_data.weeks[key].duration.total,
                        min: ajaxResponse.data.time_data.weeks[key].duration.min,
                        max: ajaxResponse.data.time_data.weeks[key].duration.max,
                        count: ajaxResponse.data.time_data.weeks[key].duration.activities,
                        avg: ajaxResponse.data.time_data.weeks[key].duration.avg,
                        total: ajaxResponse.data.time_data.weeks[key].duration.total
                    }
                )
            }

            // 7 TSS
            if (ajaxResponse.data.time_data.weeks[key].tss) {
                values_7.push(
                    {
                        y: ajaxResponse.data.time_data.weeks[key].tss.avg,
                        min: ajaxResponse.data.time_data.weeks[key].tss.min,
                        max: ajaxResponse.data.time_data.weeks[key].tss.max,
                        count: ajaxResponse.data.time_data.weeks[key].tss.activities,
                        avg: ajaxResponse.data.time_data.weeks[key].tss.avg,
                        total: ajaxResponse.data.time_data.weeks[key].tss.total
                    }
                )

            }

            // 8 IF
            if (ajaxResponse.data.time_data.weeks[key].if) {
                values_8.push(
                    {
                        y: ajaxResponse.data.time_data.weeks[key].if.avg,
                        min: ajaxResponse.data.time_data.weeks[key].if.min,
                        max: ajaxResponse.data.time_data.weeks[key].if.max,
                        count: ajaxResponse.data.time_data.weeks[key].if.activities,
                        avg: ajaxResponse.data.time_data.weeks[key].if.avg,
                        total: ajaxResponse.data.time_data.weeks[key].if.total
                    }
                )
            }


        }

        chart.data.datasets[0].data = values_0
        chart.data.datasets[1].data = values_1
        chart.data.datasets[2].data = values_2
        chart.data.datasets[3].data = values_3
        chart.data.datasets[4].data = values_4
        chart.data.datasets[5].data = values_5
        chart.data.datasets[6].data = values_6
        chart.data.datasets[7].data = values_7
        chart.data.datasets[8].data = values_8


        chart.data.labels = labels
        chart.data.datasets[0].hidden = true
        chart.data.datasets[1].hidden = true
        chart.data.datasets[2].hidden = true
        chart.data.datasets[3].hidden = true
        chart.data.datasets[4].hidden = true
        chart.data.datasets[5].hidden = true
        chart.data.datasets[6].hidden = true
        chart.data.datasets[7].hidden = true
        chart.data.datasets[8].hidden = true

        return

    }

    function setChartJsDatasetsForMonths() {


        // console.log("SET FOR months!")
        // console.log(ajaxResponse.data.time_data.months)

        let labels = []

        for (var key in ajaxResponse.data.time_data.months){
            labels.push( ajaxResponse.data.time_data.months[key].month_title )
        }

        let values_0 = []
        let values_1 = []
        let values_2 = []
        let values_3 = []
        let values_4 = []
        let values_5 = []
        let values_6 = []
        let values_7 = []
        let values_8 = []


        for (var key in ajaxResponse.data.time_data.months){



            // 0
            if (ajaxResponse.data.time_data.months[key].calories) {
                values_0.push(
                    {
                        y: ajaxResponse.data.time_data.months[key].calories.total,
                        min: ajaxResponse.data.time_data.months[key].calories.min,
                        max: ajaxResponse.data.time_data.months[key].calories.max,
                        count: ajaxResponse.data.time_data.months[key].calories.activities,
                        avg: ajaxResponse.data.time_data.months[key].calories.avg,
                        total: ajaxResponse.data.time_data.months[key].calories.total
                    }
                )
            }

            // 1
            if (ajaxResponse.data.time_data.months[key].distance) {
                values_1.push(
                    {
                        y: ajaxResponse.data.time_data.months[key].distance.total,
                        min: ajaxResponse.data.time_data.months[key].distance.min,
                        max: ajaxResponse.data.time_data.months[key].distance.max,
                        count: ajaxResponse.data.time_data.months[key].distance.activities,
                        avg: ajaxResponse.data.time_data.months[key].distance.avg,
                        total: ajaxResponse.data.time_data.months[key].distance.total
                    }
                )
            }

            // 2
            if (ajaxResponse.data.time_data.months[key].ep) {
                values_2.push(
                    {
                        y: ajaxResponse.data.time_data.months[key].ep.total,
                        min: ajaxResponse.data.time_data.months[key].ep.min,
                        max: ajaxResponse.data.time_data.months[key].ep.max,
                        count: ajaxResponse.data.time_data.months[key].ep.activities,
                        avg: ajaxResponse.data.time_data.months[key].ep.avg,
                        total: ajaxResponse.data.time_data.months[key].ep.total
                    }
                )
            }


            // 3
            if (ajaxResponse.data.time_data.months[key].avg_watt) {
                values_3.push(
                    {
                        y: ajaxResponse.data.time_data.months[key].avg_watt.avg,
                        min: ajaxResponse.data.time_data.months[key].avg_watt.min,
                        max: ajaxResponse.data.time_data.months[key].avg_watt.max,
                        count: ajaxResponse.data.time_data.months[key].avg_watt.activities,
                        avg: ajaxResponse.data.time_data.months[key].avg_watt.avg,
                        total: ajaxResponse.data.time_data.months[key].avg_watt.total
                    }
                )
            }


            // 4
            if (ajaxResponse.data.time_data.months[key].avg_watt_kg) {
                values_4.push(
                    {
                        y: ajaxResponse.data.time_data.months[key].avg_watt_kg.avg,
                        min: ajaxResponse.data.time_data.months[key].avg_watt_kg.min,
                        max: ajaxResponse.data.time_data.months[key].avg_watt_kg.max,
                        count: ajaxResponse.data.time_data.months[key].avg_watt_kg.activities,
                        avg: ajaxResponse.data.time_data.months[key].avg_watt_kg.avg,
                        total: ajaxResponse.data.time_data.months[key].avg_watt_kg.total
                    }
                )
            }

            // 5
            if (ajaxResponse.data.time_data.months[key].ftp) {
                values_5.push(
                    {
                        y: ajaxResponse.data.time_data.months[key].ftp.avg,
                        min: ajaxResponse.data.time_data.months[key].ftp.min,
                        max: ajaxResponse.data.time_data.months[key].ftp.max,
                        count: ajaxResponse.data.time_data.months[key].ftp.activities,
                        avg: ajaxResponse.data.time_data.months[key].ftp.avg,
                        total: ajaxResponse.data.time_data.months[key].ftp.total
                    }
                )
            }

            // 6
            if (ajaxResponse.data.time_data.months[key].duration) {
                values_6.push(
                    {
                        y: ajaxResponse.data.time_data.months[key].duration.total,
                        min: ajaxResponse.data.time_data.months[key].duration.min,
                        max: ajaxResponse.data.time_data.months[key].duration.max,
                        count: ajaxResponse.data.time_data.months[key].duration.activities,
                        avg: ajaxResponse.data.time_data.months[key].duration.avg,
                        total: ajaxResponse.data.time_data.months[key].duration.total
                    }
                )
            }


            // 7
            if (ajaxResponse.data.time_data.months[key].duration) {
                values_7.push(
                    {
                        y: ajaxResponse.data.time_data.months[key].tss.avg,
                        min: ajaxResponse.data.time_data.months[key].tss.min,
                        max: ajaxResponse.data.time_data.months[key].tss.max,
                        count: ajaxResponse.data.time_data.months[key].tss.activities,
                        avg: ajaxResponse.data.time_data.months[key].tss.avg,
                        total: ajaxResponse.data.time_data.months[key].tss.total
                    }
                )
            }

            // 8
            if (ajaxResponse.data.time_data.months[key].if) {
                values_8.push(
                    {
                        y: ajaxResponse.data.time_data.months[key].if.avg,
                        min: ajaxResponse.data.time_data.months[key].if.min,
                        max: ajaxResponse.data.time_data.months[key].if.max,
                        count: ajaxResponse.data.time_data.months[key].if.activities,
                        avg: ajaxResponse.data.time_data.months[key].if.avg,
                        total: ajaxResponse.data.time_data.months[key].if.total
                    }
                )
            }


        }

        chart.data.datasets[0].data = values_0
        chart.data.datasets[1].data = values_1
        chart.data.datasets[2].data = values_2
        chart.data.datasets[3].data = values_3
        chart.data.datasets[4].data = values_4
        chart.data.datasets[5].data = values_5
        chart.data.datasets[6].data = values_6
        chart.data.datasets[7].data = values_7
        chart.data.datasets[8].data = values_8


        chart.data.labels = labels
        chart.data.datasets[0].hidden = true
        chart.data.datasets[1].hidden = true
        chart.data.datasets[2].hidden = true
        chart.data.datasets[3].hidden = true
        chart.data.datasets[4].hidden = true
        chart.data.datasets[5].hidden = true
        chart.data.datasets[6].hidden = true
        chart.data.datasets[7].hidden = true
        chart.data.datasets[8].hidden = true

        return

    }
    

    function updateGraphsForSelectedType() {



        switch (activeGroupSelector) {
            case "days":
                setChartJsDatasetsForDays()
                setChartJsOptionsAxesForDays()
                break;
            case "weeks":
                setChartJsDatasetsForWeeks()
                setChartJsOptionsForWeeks()
                break;
            case "months":
                setChartJsDatasetsForMonths()
                break;
        }




        chart.data.datasets.forEach(function(dataset, index){
            if ( $.inArray(dataset.id, arrActiveButtons) != -1 ) {

                let yAxisID = chart.data.datasets[index].id

                // switch axis to left or right
                if (  $.inArray(dataset.id, arrActiveButtons) == 0 ) {
                    //left
                    chart.options.scales.yAxes[index].position = 'left'
                } else {
                    //right
                    chart.options.scales.yAxes[index].position = 'right'
                }

                // set dataset active
                chart.data.datasets[index].hidden = false

                //set yAxis active
                chart.options.scales.yAxes[index].display = true

            } else {
                chart.data.datasets[index].hidden = true
                chart.options.scales.yAxes[index].display = false
            }


        })

        chart.update();
    }


    var arrActiveButtons = []
    var activeGroupSelector = "days"
    var ajaxResponse = {}

    $("select#group-selector").on('change', function() {
        activeGroupSelector = $(this).val()
        updateGraphsForSelectedType()
        updateStatisticIndicators()
    })


    //button listener
    $("button[id^='show-']").on('click', function() {

        var clickedType = $(this).attr('id').replace("show-", "")

        // check if clickedType is already in activeButtons -> remove
        if ( $.inArray(clickedType, arrActiveButtons) != -1 ) {
            arrActiveButtons = arrayRemove(arrActiveButtons, clickedType)

            setButtonActiveAndDisableOtherButtons()
            updateGraphsForSelectedType()
            updateStatisticIndicators(clickedType)
            return
        }

        arrActiveButtons.push(clickedType)

        //check if there are already another button active. if so -> remove it, only one shall be active
        if (arrActiveButtons.length > 1) {
            arrActiveButtons.shift()
        }

        setButtonActiveAndDisableOtherButtons()
        updateGraphsForSelectedType()
        updateStatisticIndicators(clickedType)
    })


    function updateStatisticIndicators() {


        // hide indicators
        $('.statistic-indicators').hide()


        var indicator = null

        switch (arrActiveButtons[0]) {
            case "calories":
                // total calories
                var indicator = $('#total_calories')

                indicator.text("Total calories: " + ajaxResponse.data.total_calories + " kcal")

                break;

            case "distance":
                // total distance
                var indicator = $('#total_distance')

                indicator.text("Total distance: " + ajaxResponse.data.total_distance + " km")
                break;

            case "ep":
                // total ep
                var indicator = $('#total_effort_points')

                indicator.text("Total Effort Points: " + ajaxResponse.data.total_effort_points)
                break;

            case "duration":
                // total duration
                var indicator = $('#total_duration')


                var duration = getHoursMinutesString(ajaxResponse.data.total_duration)

                indicator.text("Total duration: " + duration)
                break;

            case "watt":
                // average watt
                var indicator = $('#average_watt')
                indicator.text("avg. Watt: " + ajaxResponse.data.avg_watt)
                break;

            case "watt-kg":
                //average watt/kg
                var indicator = $('#average_watt_kg')
                indicator.text("avg. Watt/KG: " + ajaxResponse.data.avg_watt_kg)
                break;

            case "ftp":
                //average ftp
                var indicator = $('#average_ftp')
                indicator.text("avg. FTP: " + ajaxResponse.data.avg_ftp)
                break;

            case "tss":
                //average ftp
                var indicator = $('#average_tss')
                indicator.text("avg. TSS: " + ajaxResponse.data.avg_tss)
                break;

            case "if":
                //average if
                var indicator = $('#average_if')
                indicator.text("avg. IF: " + ajaxResponse.data.avg_if)
                break;

        }


        if ( indicator != null ) {
            indicator.show()
        }


    }


    function getActiveButton() {
        var activeButton = $("button[id^='show-']").hasClass('active')
        var active = ""

        if (activeButton.length > 0) {
            active = activeButton.attr('id').replace("show-", "")
        }
        return active
    }



    function setButtonActiveAndDisableOtherButtons() {
        const selectorButtons = $("button[id^='show-']")
        selectorButtons.each(function(){

            var type = $(this).attr('id').replace("show-", "")

            if ( $.inArray(  type, arrActiveButtons )  != -1  ) {
                $(this).addClass("active")
                return
            }
            $(this).removeClass("active")
        })
    }




    function arrayRemove(arr, value) {

        for( var i = 0; i < arr.length; i++){

            if ( arr[i] === value) {
                arr.splice(i, 1);
            }

        }

        return arr

    }


    if (typeof(arrDatesWithActivities) != 'undefined' && arrDatesWithActivities != null) {
        getDateForDateRange(initialStartDate, initialEndDate);
    }


    function getHoursMinutesString(seconds, showLongLabel = false) {
        let int_hours = Math.floor(seconds / 3600); // Berechnet die Stunden
        let remaining_seconds = seconds % 3600; // Restliche Sekunden nach Stundenberechnung
        let int_minutes = Math.floor(remaining_seconds / 60); // Berechnet die Minuten
        let int_seconds = remaining_seconds % 60; // Berechnet die Sekunden

        let stringHours = int_hours > 0 ? String(int_hours).padStart(2, "0") + ":" : "";
        let stringMinutes = String(int_minutes).padStart(2, "0");
        let stringSeconds = String(int_seconds).padStart(2, "0");

        var returnString = stringHours + stringMinutes + ":" + stringSeconds

        if (int_hours > 0)  {

            if (showLongLabel) {
                returnString = returnString + " hours"
            } else {
                returnString = returnString + " h"
            }

        } else {

            if (showLongLabel) {
                returnString = returnString + " minutes"
            } else {
                returnString = returnString + " min"
            }
        }


        return returnString
    }


    function getMinutesSecondsString(seconds) {

        let int_minutes = Math.floor(seconds/60)
        let int_seconds = seconds % 60

        let stringSeconds = String(int_seconds).padStart(2, "0")


        return int_minutes + ":" + stringSeconds

    }


    function setNewTcxUrl(start_date, end_date) {
        var TCXLink = $('#export-to-tcx-link')

        if (TCXLink.length > 0 ) {
            TCXLink = TCXLink.data('base-tcx-url')
        }
        var new_url = TCXLink + "?start_date="+start_date+"&end_date="+end_date

        $('#export-to-tcx-link').attr('href', new_url)

    }


    $("#graph-show-activities").click(function() {
        $('html,body').animate({
                scrollTop: $("#activity-list").offset().top -100
            },
            'slow');
    });



});

