import $ from "jquery";

$( document ).ready(function() {

    $(window).on('resize', function() {
        if ( window.innerWidth < 992 ) {
            // set normal images to tiles
            $(".gic-tiles-grid>.tile").each(function() {
                $(this).css("background", "")
            })
        } else {
            // set mobile images to tiles
            $(".gic-tiles-grid>.tile").each(function() {
                var backgroundImage = $(this).data("desktop-background")

                $(this).css("background-image", backgroundImage)
            })
            closePopup()
        }

    });


    $(".gic-tiles-grid>.tile").on("click", function() {

        //only
        var viewPointWidth = window.innerWidth
        var jsObject = $(this)
        var targetOffsetOnClose = $(this).parent().offset().top

        if ( window.innerWidth >= 992 ) {
            console.log("CLICK", jsObject.find("h2").html())
            showPopup(jsObject, targetOffsetOnClose)
        }
    })

    $(".gic-tiles-grid>div.fullcover>div.popup-close").on("click", function(){
        closePopup()
    })


    function closePopup() {

        // get target offset from body
        var targetOffset = $("body").data('offset-on-close')
        $('html, body').animate({
            scrollTop: targetOffset
        }, 10);

        $("div.fullcover").toggle(false)
    }

    function showPopup( jQobject, targetOffsetOnClose) {

        var tileId = jQobject.attr("id")
        tileId = getIdFromElementId(tileId)


        console.log(targetOffsetOnClose)

        $("body").data('offset-on-close', targetOffsetOnClose )
        jQobject.next("div.fullcover-"+tileId).toggle(true)

        console.log("popup!")
    }


    function getIdFromElementId(elementId) {
        var arrSplits = elementId.split("-")
        return arrSplits[arrSplits.length - 1]
    }


    setTimeout(function(){
        $(window).trigger('resize')
    }, 50)



});



