import { tns } from 'tiny-slider/src/tiny-slider'

const AppSlider = function( id ){
    this.slider = document.getElementById( id );
};

AppSlider.prototype = {
    init: function(){
        this.listenEvents();
    },
    listenEvents: function(){
        const slider = tns({
            container: '.my-slider',
            items: 3,
            slideBy: 1,
            autoplay: false,
            mouseDrag: true,
            lazyload: true,
            autoplayButtonOutput: false,
            nav: false,
            loop: false,
            responsive: {
                640: {
                    edgePadding: 20,
                    gutter: 20,
                    items: 3
                },
                700: {
                    gutter: 30
                },
                900: {
                    items: 3
                },
                1400: {
                    items: 5
                }
            }
        });
    }
};

document.addEventListener('DOMContentLoaded', function(){

    if(document.getElementById( 'categorySlider' )){
        new AppSlider('categorySlider').init();
    }

});
