const svg = document.getElementsByClassName('home-p')[0];

let animationStatus = false;

let scrollIndicator = {

    init: function () {
        this.controller = new ScrollMagic.Controller();

        this.setupEvent();


    },
    setupEvent: function () {
        let self = this;


        let tween = TweenMax.to(".home-p", 0.5, {scale: 2.5});

        let scene = new ScrollMagic.Scene({
            triggerElement: "#home--start",
            duration: "100%",
            offset: 500
        });
        let scene2 = new ScrollMagic.Scene({
            triggerElement: "#home--start",
            offset: 1400
        });

        scene.setTween(tween)
                    .addIndicators({name: "1 (duration: 0)"})
                    .addTo(self.controller);

        scene2.setTween(".home-p", 0.5, {backgroundColor: "green", scale: 2.5})
            .addIndicators({name: "1 (duration: 1600)"})
            .addTo(self.controller)
            .on("enter leave", function (e) {
                self.elementAnimation();
            });


    },
    elementAnimation: function () {

        if(!animationStatus){
            animationStatus = true;
        }else{
            animationStatus = false;
        }

    }

};
if(document.getElementById('home--start')){
    scrollIndicator.init();
}
