import $ from "jquery";
var moment = require('moment')


$( document ).ready(function() {


    function getEventIdForTimer( elementName ) {
        let arrTemp = elementName.split("-")
        return arrTemp[2]
    }



    // get all event timer
    let eventTimer = $('.event-timer-button')

    eventTimer.each(function (  ) {
        defineAndStartTimer( this )
    } ) ;


    function defineAndStartTimer( element ) {
        // Set the date we're counting down to
        let distance = $(element).data('event-in-seconds');

        //distance = 10

        let eventTimeSpan = document.getElementById("event-" + getEventIdForTimer( $(element).attr('id') ) +  "-timer-time")

        // Update the count down every 1 second
        var x = setInterval(function() {

            distance = distance - 1

            let hours = parseInt(moment.duration(distance*1000).asHours())
            let distance_left = distance - hours*3600

            let countdown = moment("1900-01-01 00:00:00").add(distance_left, 'seconds').format("mm:ss")

            if (distance > 0) {
                $(element).show();
                eventTimeSpan.innerHTML = hours.toString().padStart(2, '0') + ':' +countdown;
            } else {
                $(element).hide();
            }
        }, 1000);
    }
















});



