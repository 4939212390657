const CustomSelect = function(elms){
    this.elms = document.getElementsByClassName(elms);
};

const dropdownIcon = () => {
    const dropdown = document.createElement('span');
    dropdown.innerHTML = `<svg width="14px" height="7px" viewBox="0 0 10 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Delivery" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Transactions-(Landing)" transform="translate(-1360.000000, -29.000000)" fill="#CDCFD3" fill-rule="nonzero">
        <g id="Group-4" transform="translate(1360.000000, 29.000000)">
            <polygon id="Shape" points="0 0 5 5 10 0"></polygon>
        </g>
    </g>
    </g>
</svg>`;
    return dropdown;
};

let items = [];

CustomSelect.prototype = {

    init: function () {
        this.listenEvents();
    },
    listenEvents: function () {
        let self = this;

        let labelText = '',
            elms = '';

        for(let i = 0; i < self.elms.length; i++){
            if (this.elms[i].nodeName == "SELECT" ) {

                for (let a = 0; a < this.elms[i].options.length; a++) {
                    items.push({id: a, name: this.elms[i].options[a].innerText, title: this.elms[i].options[a].value});
                }

                if ( typeof self.elms[i].previousElementSibling !== "undefined" && self.elms[i].previousElementSibling !== null) {
                    labelText = self.elms[i].previousElementSibling.innerText;
                } else {
                    labelText = "";
                }

                elms = self.elms[i];



                self.dropdown(elms, labelText);

                items = [];
            }
        }
        const inputs = document.getElementsByClassName('input');
        for(let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener("click", function(){
                self.toggleDropdown(this);
            });
        }
        const structures = document.querySelectorAll('.structure > div');
        for(let i = 0; i < structures.length; i++){
            structures[i].addEventListener("click", function(){
                self.selectOption(this.id, this.getAttribute('data-name'), this.parentNode.previousSibling)

            });
        }

    },
    dropdown: function (elm, label) {
        let self = this;

        const component = document.createElement("div");
        const input = self.createInput(label);
        const dropdown = self.showDropdown();

        component.appendChild(input);
        component.appendChild(dropdown);

        elm.parentNode.appendChild(component);
    },
    createInput: function (label) {
        let self = this;
        const input = document.createElement("div");
        input.classList = "input__holder";
        input.classList = "input";


        const inputPlaceholder = document.createElement("div");
        inputPlaceholder.classList = "input__placeholder";

        const placeholder = document.createElement("p");
        placeholder.textContent = label;
        placeholder.classList.add('placeholder');

        inputPlaceholder.appendChild(placeholder);
        inputPlaceholder.appendChild(dropdownIcon());
        input.appendChild(inputPlaceholder);

        return input;
    },
    showDropdown: function () {
        const structure = document.createElement("div");
        structure.classList.add("structure", "hide");

        items.forEach(user => {
            const {
                id,
                name,
                title
            } = user;
            const option = document.createElement("div");
            option.setAttribute("id", id);
            option.setAttribute("data-name", name);

            const n = document.createElement("p");
            n.textContent = name;

            option.appendChild(n);
            structure.appendChild(option);
        });

        return structure;
    },
    toggleDropdown: function (elm) {
        let dropdown = elm.nextSibling;
        dropdown.classList.toggle("hide");
        let input = elm;
        input.classList.toggle("input__active");
    },
    selectOption: function (id,att,elm2) {
        let self = this;
        const text = elm2.childNodes[0];
        text.textContent = att;
        text.classList.add('input__selected');
        self.toggleDropdown(elm2);
        self.referInput(elm2, id);
    },
    referInput: function (elm, id) {
        let getSelect = elm.parentNode.previousElementSibling;
        getSelect.selectedIndex = id;
    }
};

document.addEventListener('DOMContentLoaded', function(){
    if(document.getElementsByClassName('special-js-select')[0]){
        //NICHT MEHR VERWENDEN!
        new CustomSelect('select').init();
    }
});
