const NavBar = function( id ){
    this.bar = document.getElementById( id );
    this.header = document.getElementsByTagName('header')[0];
    this.navWrap = document.getElementById('nav-control--main');
    this.navList = document.getElementById('nav-list-wrap');
};

let barStatus = false;

NavBar.prototype = {
    init: function(){
        this.listenEvents();
    },
    listenEvents: function(){
        let self = this;
        this.bar.addEventListener('click', function(){
            if(!barStatus){

                self.addClass(this, 'open');
                self.addClass(self.header, 'open');
                self.addClass(self.navList, 'open');
                self.addClass(self.navWrap, 'open');

                //blurred background
                self.addClass(self.header, 'blurred-mobile-background');
                document.documentElement.style.overflow = 'hidden';
                document.body.scroll = "no";

                barStatus = true;
            }else{
                self.removeClass(this, 'open');
                self.removeClass(self.header, 'open');
                self.removeClass(self.navList, 'open');
                self.removeClass(self.navWrap, 'open');

                //blurred background
                self.removeClass(self.header, 'blurred-mobile-background');
                document.documentElement.style.overflow = 'scroll';
                document.body.scroll = "yes";


                barStatus = false;

            }
        })
    },
    addClass:function(element, name){
        element.classList.add(name);

    },
    removeClass:function(element,name){
        element.classList.remove(name);
    }
};

document.addEventListener('DOMContentLoaded', function(){
    if(document.getElementById( 'nav-icon' )){
       new NavBar('nav-icon').init();
    }
});