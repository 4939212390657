import $ from "jquery";

$( document ).ready(function() {

    // voucher code reedeming
$(".check_voucher_code_button").on("click", function(e) {

    var voucherFields = $('.voucher_code_input_field')
    var voucherCode = ""

    voucherFields.each(function(index, element) {
        if (element.value != "") {
            voucherCode = element.value
        }


    })


   console.log("voucherCode: " + voucherCode)


    if (voucherCode != "") {
        checkVoucherCode(voucherCode)
    }
})

function checkVoucherCode(voucherCode) {
    var locale_url = $('meta[name=locale]').attr("content")
    var CSRFToken = $("meta[name='csrf-token']").attr('content')

    var redirectUrl = $('#redirect-to-account-path').html()

    var modalVoucherCodeProfile = $('#voucher_code_modal_profile')
    var modalVoucherCodeError = $('#voucher_code_modal_error')
    var modalTextFieldError = $('#voucher_code_modal_result_text_error')
    var modalTextFieldProfile = $('#voucher_code_modal_result_text_profile')
    

    voucherCode = voucherCode.trim()

    $.ajax({
        ///:locale/account/voucher_codes/:voucher_code_id/check_vc(.:format)
        url : '/'+ locale_url +'/account/voucher_codes/validate/check_vc',
        dataType: "json",
        method : "POST",
        headers: {
            'X-CSRF-TOKEN': CSRFToken
        },
        data:{
            voucher_code: voucherCode,
        },
        success:function(response) {

            modalTextFieldProfile.html(response.message)
            modalVoucherCodeProfile.modal('show')

            console.log(redirectUrl)

            setTimeout(function(){
                window.location.replace(redirectUrl);
            }, 5000);
        },
        error:function(e){
            //error handling
            if (e.responseJSON.error_code == "_NOT_INVOICEABLE_") {
                modalTextFieldProfile.html(e.responseJSON.message)
                modalVoucherCodeProfile.modal('show')
                return
            }

            if (e.responseJSON.error_code == "_INVALID_") {
                modalTextFieldError.html(e.responseJSON.message)
                modalVoucherCodeError.modal('show')

                return
            }

            if (e.responseJSON.error_code == "_CM_INVALID_") {
                modalTextFieldError.html(e.responseJSON.message)
                modalVoucherCodeError.modal('show')

                return
            }

            if (e.responseJSON.error_code == "_VA_INVALID_") {
                modalTextFieldError.html(e.responseJSON.message)
                modalVoucherCodeError.modal('show')

                return
            }

            if (e.responseJSON.error_code == "_PA_INVALID_") {
                modalTextFieldError.html(e.responseJSON.message)
                modalVoucherCodeError.modal('show')

                return
            }

            if (e.responseJSON.error_code == "_NOT_INVOICEABLE_") {
                modalTextFieldError.html(e.responseJSON.message)
                modalVoucherCodeError.modal('show')

                return
            }

            if (e.responseJSON.error_code == "_CM_ALREADY_GIC_MEMBER_") {
                modalTextFieldError.html(e.responseJSON.message)
                modalVoucherCodeError.modal('show')

                return
            }


            modalTextFieldError.html(e.responseJSON.message)
            modalVoucherCodeError.modal('show')
            return;

        }
    });

}

});

