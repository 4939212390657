import { CookieConsent } from 'cookieconsent';

document.addEventListener('DOMContentLoaded', function(){

    window.cookieconsent.initialise({
        cookieconsent: {
          status: "allow"
        },
        cookie: {
            domain: cookieTranslation.host,
            expiryDays: 30,
        },
        palette:{
            popup: {background: "#FF2D55"},
            button: {background: "#4aedfe"},
        },
        revokable: false,
        onStatusChange: function(status) {
            console.log(this.hasConsented() ?
                'enable cookies' : 'disable cookies');
        },
        law: {
            regionalLaw: true,
        },
        location: false,
        position: "bottom",
        "onPopupOpen": function(){
            //workaround for issue on ipad
            var floatingEl = document.getElementsByClassName("cc-floating")
            if(floatingEl && floatingEl[0]){
                floatingEl[0].classList.remove("cc-floating")
                //floatingEl[0].element.classList.add("cc-banner")
            }

            var revokeTab = document.getElementsByClassName('cc-revoke')
            if(revokeTab && revokeTab[0]){
                revokeTab[0].parentNode.removeChild(revokeTab[0])
            }

        },
        content: {
            //header: 'Cookies used on the website!',
            message: cookieTranslation.cookieText,
            dismiss: cookieTranslation.buttonText,
            allow:  cookieTranslation.buttonText,
            //deny: 'Decline',
            link: false,
            close: '&#x274c;',
            //policy: false,
            target: '_blank',
        }
    });
});
