import $ from "jquery";


$( document ).ready(function() {

    var countrySelection = $("select[name='club[country_id]']");
    var countryStateSelection = $("select[name='club[country_state_id]']");

    var arrPossibleCountryState = getPossibleCountryIdsInStates();

    countrySelection.on('change', function(e){
        if ( arrPossibleCountryState.indexOf(  parseInt($(this).val())  ) != -1  ) {
            countryStateSelection.show();
        } else {
            //hide state selection
            countryStateSelection.val(null)
            countryStateSelection.hide();
        }
    });

    setTimeout(function () {
        countrySelection.trigger('change');
    }, 200)


    function getPossibleCountryIdsInStates() {

        var arrCountryId = [];
        var countryStateSelection = $("select[name='club[country_state_id]']");
        var stateOptions = countryStateSelection.find('option');

        stateOptions.each(function(index) {
            if (arrCountryId.indexOf( $(this).data('country') ) == -1 ) {
                arrCountryId.push($(this).data('country'));
            } else {
                return
            }
        } );

        return arrCountryId;

    }



    //#####################

    $('#add_product_button').on("click", function(e){
        addProduct(e.target)
    })


    $( "[id^='remove_product_persistent_']" ).on('click', function(e){
        $(this).parent().remove()
    });


    function addProduct(target) {

        //get data
        var productId = "new-"+ makeid(4);

        //render answer
        var JsProductField = $('#template_new_product').html();
        Mustache.parse(JsProductField);   // optional, speeds up future uses

        var rendered = Mustache.render(JsProductField,
            {
                product_id: productId
            });

        $('#products_area').append(rendered);

        $("#remove_product_"+productId).on("click", function(e){
            $(this).parent().remove()
        })

    }

    //#####################

    $('#add_digital_product_button').on("click", function(e){
        addDigitalProduct(e.target)
    })


    $( "[id^='remove_digital_product_persistent_']" ).on('click', function(e){
        $(this).parent().remove()
    });


    function addDigitalProduct(target) {

        //get data
        var digitalProductId = "new-"+ makeid(4);

        //render answer
        var JsDigitalProductField = $('#template_new_digital_product').html();
        Mustache.parse(JsDigitalProductField);   // optional, speeds up future uses

        var rendered = Mustache.render(JsDigitalProductField,
            {
                product_id: digitalProductId
            });

        $('#digital_products_area').append(rendered);

        $("#remove_digital_product_"+productId).on("click", function(e){
            $(this).parent().remove()
        })

    }


    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    //##################### COURSES

    $('#add_course_button').on("click", function(e){
        addCourse(e.target)
    })


    $( "[id^='remove_course_persistent_']" ).on('click', function(e){
        $(this).parent().remove()
    });


    function addCourse(target) {

        //get data
        var courseId = "new-"+ makeid(4);

        //render answer
        var JsCourseField = $('#template_new_course').html();
        Mustache.parse(JsCourseField);   // optional, speeds up future uses

        var rendered = Mustache.render(JsCourseField,
            {
                course_id: courseId
            });

        $('#courses_area').append(rendered);

        $("#remove_course_"+courseId).on("click", function(e){
            $(this).parent().remove()
        })

    }






});



