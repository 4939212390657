const FlashMassage = function (elm){
    this.elm = document.getElementsByClassName(elm)[0];
};

FlashMassage.prototype = {
    init: function(){
        this.listenEvents();
    },
    listenEvents: function(){
        let self = this;
        setTimeout(function(){
            self.fadeOutEffect(self.elm);
        }, 3500);
    },
    fadeOutEffect: function (target) {
        const fadeEffect = setInterval(function () {
            if(!target.style.opacity){
                target.style.opacity = 1;
            }
            if(target.style.opacity> 0){
                target.style.opacity -= 0.1;
            }else{
                clearInterval(fadeEffect);
            }
        }, 100)
    }
};

document.addEventListener('DOMContentLoaded', function(){
    if(document.getElementsByClassName( 'flash_section' )[0]){
        new FlashMassage('flash_section').init();
    }
});